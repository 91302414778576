import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField, TextareaAutosize } from '@mui/material';
import axios from 'axios';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import QRCode from 'qrcode-generator';
import React, { useEffect, useRef, useState } from 'react';
import { BiLogOut } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { GiFinishLine } from "react-icons/gi";
import { IoIosAddCircleOutline, IoMdEye } from "react-icons/io";
import { MdCategory, MdClose, MdDelete, MdOutlineSaveAlt, MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { SiTraefikproxy, SiZenn } from "react-icons/si";
import { Tb360View } from "react-icons/tb";
import Masonry from 'react-masonry-css';
import { useParams } from 'react-router-dom';
import { FaDownload } from "react-icons/fa6";
import { toast } from 'react-toastify';
import { colors, convertToMMFormat, designType, encryptSlug, generateRandomOTP, generateSlugByManualData } from '../../common/globals';
import "./image.css";
import Autosuggest from 'react-autosuggest';

const Image = () => {
  const debounceTimeout = useRef(null);
  const { slug } = useParams();
  const loadMoreStep = 20;
  const [otpVerified, setOtpVerified] = useState(false); // New state for OTP verification
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [suggestedValues, setSuggestedValues] = useState([]);
  const [selectAllDesignId, setSelectAllDesignId] = useState([]);
  const [otpInput, setOtpInput] = useState(''); // State to hold user's OTP input
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [compareImages, setCompareImages] = useState([]);
  const [images, setImages] = useState([])
  const drawerContentRef = useRef();
  const [isScrolled, setIsScrolled] = useState(false);
  const [showSelected, setShowSelected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [currentSelectedImage, setCurrentSelectedImage] = useState(null);
  const [visibleImages, setVisibleImages] = useState(20); // Number of initially visible images
  const [selectedSize, setSelectedSize] = useState([]);
  const [loadedImages, setLoadedImages] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedDesignType, setSelectedDesignType] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [selectedName, setSelectedName] = useState([]);
  const [finishData, setFinishData] = useState([]);
  const [sizeByfinishData, setSizeFinishData] = useState([]);
  const [updateCategoryListData, setUpdateCategoryListData] = useState([]);
  const [attachmentData, setAttachmentData] = useState([]);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [totalImages, setTotalImages] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [apiCallCompleted, setApiCallCompleted] = useState(false);
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [formSubmissionStatus, setFormSubmissionStatus] = useState('idle'); // 'idle', 'loading', 'success', 'error'
  const [showFormPopup, setShowFormPopup] = useState(false);
  const debounceTimeoutRef = useRef(null);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [sliderImages, setSliderImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openSingleConfirmation, setOpenSingleConfirmation] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');
  const [comment, setComment] = useState('');
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [designName, setDesignName] = useState('');
  const [addDesignDetails, setAddDesignDetails] = useState();
  const [setDeleteItem, setSetDeleteItem] = useState();
  const [uploadImage, setUploadImage] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState({ type: '', message: '' });
  const imagesContainerRef = useRef();
  const [userName, setUserName] = useState('');
  const [generateUrl, setGenerateUrl] = useState('');
  const [generateOtp, setGenerateOtp] = useState('');
  const [viewAttachmentsOpen, setViewAttachmentsOpen] = useState(false);
  const [showFaces, setShowFaces] = useState(false);
  const [showComparision, setShowComparision] = useState(false);
  const [showDesignImages, setShowDesignImages] = useState([]);
  const [editedMessage, setEditedMessage] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteAttachmentId, setDeleteAttachmentId] = useState(null);
  const [repeaterItems, setRepeaterItems] = useState([
    { id: 1, file: null, text: '' },
  ]);

  const totalQuantityByUnit = {};

  // Iterate over the array and calculate the total quantity for each unit
  selectedImages.forEach((design) => {
    if (design.quantity !== undefined && design.quantity !== null && design.unit) {
      const unit = design.unit.toLowerCase(); // Normalize unit to lowercase
      totalQuantityByUnit[unit] = (totalQuantityByUnit[unit] || 0) + design.quantity;
    }
  });

  useEffect(() => {
    if (isModalOpen) {
      const imagePromises = sliderImages.map((image) => {
        return new Promise((resolve, reject) => {
          const img = new window.Image();
          img.src = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${image.filePath}`;
          img.onload = () => resolve();
          img.onerror = (error) => reject(error);
        });
      });

      Promise.all(imagePromises)
        .then(() => setImagesLoaded(true))
        .catch((error) => console.error('Error loading images:', error));
    }
  }, [isModalOpen]);

  useEffect(() => {
    const submissionTime = localStorage.getItem('otpSubmissionTime');
    const verifiedSlug = localStorage.getItem('verifiedSlug');

    // Get the slug from the URL params
    if (submissionTime && verifiedSlug && slug) {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - parseInt(submissionTime, 10);
      const thirtyMinutesInMillis = 5 * 60 * 60 * 1000; // 5 hours in milliseconds
      if (timeDifference <= thirtyMinutesInMillis && verifiedSlug === slug) {
        setOtpVerified(true);
      } else {
        // If the time difference is more than 30 minutes or slugs don't match, reset the verification status
        resetVerificationStatus();
      }
    } else {
      // If no OTP submission time, verified slug, or URL slug found, reset the verification status
      resetVerificationStatus();
    }
  }, [slug]); // Include slug in the dependency array to trigger the effect when slug changes

  const resetVerificationStatus = () => {
    setOtpVerified(false);
    localStorage.removeItem('otpSubmissionTime');
    localStorage.removeItem('verifiedSlug');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('selectedOption');
    localStorage.removeItem('isAdmin');
    setSelectedImages([])
  };

  useEffect(() => {
    if (otpVerified) {
      getDesignData();
      getAllAttachment()
    }
  }, [otpVerified, selectedName, selectedSize, selectedCategory, selectedColor, searchQuery, selectedDesignType, sortBy]);

  useEffect(() => {
    getAllWishList();
    getAllFinishList();
    const submissionTime = localStorage.getItem('otpSubmissionTime');
    const thirtyMinutesInMillis = 5 * 60 * 60 * 1000; // 5 hours in milliseconds

    // Show OTP popup only if there is no submission time or the time difference is more than 30 minutes
    if (!submissionTime || (submissionTime && (new Date().getTime() - parseInt(submissionTime, 10)) > thirtyMinutesInMillis)) {
      if (slug) {
        setShowOtpPopup(true);
      }
    }
  }, [slug]);

  // Add an event listener to track the scroll position
  useEffect(() => {
    const handleScroll = () => {
      // Show the button when the user scrolls beyond a certain point (e.g., 200 pixels)
      setShowScrollButton(window.scrollY > 200);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const drawerContent = drawerContentRef.current;

    const handleScroll = () => {
      if (drawerContent) {
        const scrollTop = drawerContent.scrollTop;
        setIsScrolled(scrollTop > 100);
      }
    };

    if (drawerContent) {
      drawerContent.addEventListener('scroll', handleScroll);

      return () => {
        drawerContent.removeEventListener('scroll', handleScroll);
      };
    }

    // Return a no-op function if drawerContent is null
    return () => { };
  }, [drawerContentRef.current, showSelected]);


  const getDesignData = async () => {
    try {
      setLoading(true);
      if (
        selectedName.length > 0 ||
        selectedSize.length > 0 ||
        selectedCategory.length > 0 ||
        selectedColor !== '' ||
        selectedDesignType !== '' ||
        sortBy !== ''
      ) {
        setApiCallCompleted(false);
      }
      const filters = {
        selectedName,
        selectedSize,
        selectedCategory,
        selectedColor,
        searchQuery,
        selectedDesignType,
        sortBy,
        slug
      };
      const response = await axios.post(
        `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/design/product-design-list`,
        JSON.stringify(filters),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const { data } = response;
      if (data && data.status === 200) {
        const uniqueDesigns = Array.from(
          new Map(data.designs.map((design) => [design.des_id, design])).values()
        );
        setTotalImages(uniqueDesigns.length);
        setLoadedImages(uniqueDesigns);
        setImages(uniqueDesigns.slice(0, visibleImages));
      } else {
        if (data.error_code === "read_count_expired") {
          toast.error("No of read count expire. Please contact your administrator for assistance.")
        } else if (data.error_code === "Wish_list_expired") {
          toast.error("Your Wish List has expired. Please contact your administrator for assistance.");
        } else {
          toast.error("Something went wrong.")
        }
      }
    } catch (error) {
      console.error('Error fetching design data:', error);
    } finally {
      setApiCallCompleted(true);
      setLoading(false);
    }
  };

  // Your loader component
  const Loader = () => {
    return <div className="loader"></div>; // Replace this with your actual loader component
  };

  const getAllWishList = async () => {
    try {
      setLoading(true);
      // Retrieve emailInput from local storage
      const emailInput = localStorage.getItem('userEmail');
      const { data } = await axios.get(
        `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/user-wish-list/get-wist-list-data`,
        { params: { slug, emailInput: emailInput } }
      );
      if (data && data.designs) {
        setSelectedImages(data.designs);
      }
    } catch (error) {
      console.error('Error fetching finish data:', error);
    } finally {
      setLoading(false);
    }
  };
  const getAllAttachment = async () => {
    try {
      setLoading(true);
      // Retrieve emailInput from local storage
      const emailInput = localStorage.getItem('userEmail');
      const { data } = await axios.get(
        `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/design-attach/get-attachment-data`,
        { params: { slug, emailInput: emailInput } }
      );
      if (data) {
        setAttachmentData(data.attachment)
      }
    } catch (error) {
      console.error('Error fetching attachment data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getAllFinishList = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/design/get-all-finish-list`,
        { params: { slug } }
      );

      if (data) {
        setFinishData(data.data);
      }
    } catch (error) {
      console.error('Error fetching finish data:', error);
    } finally {
      setLoading(false);
    }
  };

  const incrementNoOfReads = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/design/increment-reads-count`,
        JSON.stringify({ slug }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error fetching finish data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = (image) => {
    setCurrentSelectedImage(image);
    setSliderImages(image.design_images);
    setIsModalOpen(true);
    setCurrentIndex(0)
  };

  const handleEyeIconClick = (image) => {
    if (image && image.design_images && image.design_images.length > 0) {
      setShowFaces(true); // Show the popup if images are available
      setShowDesignImages(image.design_images)
    }
  }

  const handleShowComparision = (type) => {
    setShowComparision(type); // Show the popup if images are available
  }

  const handleStarClick = async (image) => {
    try {
      const storedEmail = localStorage.getItem('userEmail');
      const formData = {
        slug,
        storedEmail,
        design_id: image.des_id
      }

      // Make the API call
      const response = await axios.post(
        `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/user-wish-list/update-design-star-data`,
        formData, // Adjust the payload as needed
      );
      if (response.data) {
        getAllWishList()
      }
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error('Error generating catalog:', error);
    }
  };

  const handleAttachmentStarClick = async (image) => {
    try {
      const storedEmail = localStorage.getItem('userEmail');
      const formData = {
        slug,
        storedEmail,
        id: image._id
      }

      // Make the API call
      const response = await axios.post(
        `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/design-attach/update-attachment-star-data`,
        formData, // Adjust the payload as needed
      );
      if (response.data) {
        getAllAttachment()
      }
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error('Error generating catalog:', error);
    }
  };

  const handleImageSelect = async (image) => {
    setSelectedImages((prevSelectedImages) => {
      let selectedArray = Array.isArray(prevSelectedImages) ? prevSelectedImages : [];
      const isImageSelected = selectedArray.some((selected) => selected.des_id === image.des_id);
      // If the image is not already selected, add it to the array
      if (!isImageSelected) {
        return [image, ...selectedArray];
      } else {
        return [image, ...selectedArray.filter((selected) => selected.des_id !== image.des_id)];
      }
      // If the image is already selected, return the current array without modification
      // return selectedArray;
    });

    const emailInput = localStorage.getItem('userEmail');
    await axios.post(
      `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/user-wish-list/add`,
      { image, slug, emailInput }, // Adjust the payload as needed
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    // Handle selected image to compare
    handleCompareImage(image);
  };

  const handleCompareImage = (image) => {
    if (!compareImages || compareImages.length < 2) {
      setCompareImages((prevSelectedImages) => {
        const selectedArray = Array.isArray(prevSelectedImages) ? prevSelectedImages : [];
        const isImageSelected = selectedArray.some((selected) => selected.des_id === image.des_id);
        // If the image is not already selected, add it to the array
        if (!isImageSelected) {
          return [image, ...selectedArray];
        } else {
          return [image, ...selectedArray.filter((selected) => selected.des_id !== image.des_id)];
        }
        // If the image is already selected, return the current array without modification
        // return selectedArray;
      });
    }
  };

  const removeItemFromCart = (image) => {
    const desId = image.des_id;
    removeItemFromWishList([desId]);
    setTimeout(async () => {
      // Remove the item from selectedImages
      setSelectedImages((prevSelectedImages) =>
        prevSelectedImages ? prevSelectedImages.filter((selected) => selected !== image) : []
      );
    }, 1000);
  };

  const removeItemFromWishList = async (optionalParameter = null) => {
    try {
      setLoading(true);
      const designId = optionalParameter ? optionalParameter : selectAllDesignId
      // Retrieve emailInput from local storage
      const emailInput = localStorage.getItem('userEmail');

      // Make the API call to remove the item from the wish list
      await axios.post(
        `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/user-wish-list/delete-wist-list-data`,
        { slug, emailInput, designId }
      );

      toast.success("The design has been successfully removed from your wish list.");
      setSelectAll(false);
      setSelectAllDesignId([])
      if (optionalParameter === null) {
        setSelectedImages([])
      }
      setSetDeleteItem("")
      getAllWishList();
    } catch (error) {
      console.error('Error removing item from wish list:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (image) => {
    setEditDialogOpen(true);
    setAddDesignDetails(image)
    setDesignName(image.design_name || '')
    setQuantity(image.quantity || '');
    setPrice(image.price || '');
    setComment(image.comment || '');
    setSelectedOption(image.unit || localStorage.getItem('selectedOption'));
    if (image.design_name || image.quantity || image.price || image.comment || image.unit) {
      setIsEdit(true)
    }
  };

  /**
   * The above code defines three functions in JavaScript that handle removing an item from a wish list,
   * including confirming the removal, removing the item, and canceling the removal.
   */
  const handleRemoveConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleSingleRemoveConfirmation = (image) => {
    setOpenSingleConfirmation(true)
    setSetDeleteItem(image)
  };

  const handleRemoveItem = () => {
    removeItemFromWishList();
    setOpenConfirmation(false);
    setShowSelected(false);
    localStorage.removeItem('selectedOption');
  }

  const handleSingleRemoveItem = (data) => {
    removeItemFromCart(data);
    setOpenSingleConfirmation(false);
  };

  const handleCancelRemove = () => {
    setOpenConfirmation(false);
  };

  const handleCancelLogout = () => {
    setIsLogoutModalOpen(false);
  };

  const handleViewAttachments = () => {
    setViewAttachmentsOpen(true);
  };

  const handleSingleCancelRemove = () => {
    setOpenSingleConfirmation(false)
    setSetDeleteItem("")
  };

  // const handleInputChange = (event) => {
  //   setSearchQuery(event.target.value);

  //   // Clear the previous timeout
  //   if (debounceTimeoutRef.current) {
  //     clearTimeout(debounceTimeoutRef.current);
  //   }

  //   // Set a new timeout for 800 milliseconds
  //   debounceTimeoutRef.current = setTimeout(() => {
  //     const trimmedValue = event.target.value.trim();
  //     setSearchQuery(trimmedValue);
  //     handleSearch(trimmedValue);
  //   }, 1500);
  // };

  const handleResetSearch = () => {
    setSearchQuery('');
    handleSearch('');
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleScrollDesigns = () => {
    const container = imagesContainerRef.current;
    if (container) {
      const { innerHeight, scrollY } = window;
      const containerBottom = container.getBoundingClientRect().bottom;
      if (innerHeight + scrollY >= containerBottom - 100 && visibleImages < totalImages) {
        setScrollPosition(innerHeight + scrollY);
        loadMoreImages();
      }
    }
  };

  const scrollToLastPosition = () => {
    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth', // Add smooth scrolling behavior
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleMoveTop = () => {
    const drawerContent = document.querySelector('.drawer-content');
    if (drawerContent) {
      drawerContent.scrollTop = 0;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollDesigns);

    return () => {
      window.removeEventListener('scroll', handleScrollDesigns);
    };
  }, [visibleImages, totalImages]);

  const loadMoreImages = () => {
    const nextBatchSize = Math.min(loadMoreStep, totalImages - visibleImages);

    if (nextBatchSize > 0) {
      setImages((prevImages) => [
        ...prevImages,
        ...loadedImages.slice(prevImages.length, prevImages.length + nextBatchSize),
      ]);
      setVisibleImages((prevVisibleImages) => prevVisibleImages + nextBatchSize);
    }
  };

  const handleResetFilters = () => {
    setSelectedName([]);
    setSelectedSize([]);
    setUpdateCategoryListData([]);
    setSelectedCategory([]);
    setSelectedColor('');
    setSelectedDesignType('');
    setSortBy('');
    // setSelectedImages([]);
    setSizeFinishData([]);
    setLoadedImages([]);
    setImages([]);
    setSearchQuery('')
    setApiCallCompleted(false)
  };
  const handleResetCompareImage = () => {
    setCompareImages([]);
  };

  const generateComparePDF = () => {
    setImageLoading(true);
    try {
      setTimeout(() => {
        const doc = new jsPDF({
          orientation: 'l', // landscape orientation
          unit: 'mm', // units are millimeters
          format: [297, 210], // A4 size in landscape mode
        });
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        let bgColor = '#D4D4D4';
        let txtColor = 'black';

        doc.setTextColor(txtColor);
        doc.setFillColor(bgColor);
        doc.rect(0, 0, pageWidth, pageHeight, 'F'); // 

        // Function to add images to PDF
        const addImageToPDF = (imageData, x, y, width, height) => {
          doc.addImage(imageData, 'JPEG', x, y, width, height);
        };
        // Function to add text to PDF
        const addTextToPDF = (text, x, y, fontSize = 12, font = 'helvetica') => {
          doc.setFont(font);
          doc.setFontSize(fontSize);
          doc.text(text, x, y);
        };

        // Calculate the width of each record based on landscape orientation
        const recordWidth = doc.internal.pageSize.getWidth() / 2;

        // Loop through data and add images to PDF
        let row = 0;
        let col = 0;
        const startY = 15; // starting y position of images
        const imagesPerRow = 3; // number of images per row
        let maxImagesToShow = 6; // maximum number of images to show

        compareImages && compareImages.forEach((item, index) => {
          const { design_images, height_width, design_name } = item;
          const [width, height] = height_width.split(" X ").map(Number);
          maxImagesToShow = (width === height) ? 12 : 6; // Update maxImagesToShow based on width and height
          addTextToPDF(design_name, index % 2 * recordWidth + 10, 8.5, 14, 'times');
          const imagesToShow = Math.min(maxImagesToShow, design_images.length);
          for (let i = 0; i < imagesToShow; i++) {
            if (col >= imagesPerRow) {
              col = 0;
              row++;
            }

            const image = design_images[i];
            const attachmentImgData = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${image.filePath}`;

            // Calculate width and height based on dimensions provided
            let imageWidth, imageHeight;
            const [width, height] = height_width.split(" X ").map(Number);
            if (width === height) {
              // If width and height are the same, set both dimensions to imageWidth
              imageWidth = 40; // Or any desired width
              imageHeight = 40; // Or any desired height
            } else {
              // Otherwise, use provided width and height
              imageWidth = 40; // Or any desired width
              imageHeight = 80; // Or any desired height
            }

            const x = col * (imageWidth + 5) + (index % 2 * recordWidth); // Adjust x position based on record index
            const y = startY + row * (imageHeight + 5);
            addImageToPDF(attachmentImgData, x + 10, y, imageWidth, imageHeight);
            col++;
          }

          // Adjust row count for the next record if no page break
          if (index % 2 !== 0 || index === 0) {
            row = 0;
            col = 0;
          }
        });

        // Save the PDF
        doc.save("compare-images.pdf");
        setImageLoading(false); // Hide loader in case of an error
      }, 300);
    } catch (error) {
      console.error('Error generating PDF:', error);
      setImageLoading(false); // Hide loader in case of an error
    }
  };

  const selectFinish = async (event, values) => {
    // 'values' contains an array of selected options
    setSelectedName(values.map((value) => value._id));

    try {
      const selectedIds = values.map((value) => value._id);
      const { data } = await axios.get(
        `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/design/get-design-size-by-finish/${selectedIds.join(',')}`,
        { params: { slug } }
      );

      if (data) {
        const uniqueSize = [...new Set(data.data.map(item => item.design_size))];
        setSizeFinishData(uniqueSize);
      }
    } catch (error) {
      console.error('Error fetching size data:', error);
    }
  };

  const selectFinishSize = async (e) => {
    setSelectedSize(
      // On autofill, we get a stringified value.
      typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
    );

    const filters = {
      selectedName,
      size: e.target.value,
      slug
    };

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/design/get-design-category`,
        JSON.stringify(filters),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (data) {
        const uniqueCategories = [...new Set(data.data.map(item => item.design_category))];
        setUpdateCategoryListData(uniqueCategories);
      }
    } catch (error) {
      console.error('Error fetching size data:', error);
    }
  };

  // const filterCategoryDesign = (e) => {
  //   setSelectedCategory(
  //     // On autofill, we get a stringified value.
  //     typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
  //   );
  // };

  const isValidImage = (image) => {
    // Check if the image has a valid source
    return image && image.design_file;
  };

  const handleImageError = (image) => {
    // Handle the image error, for example, by marking the image as invalid
    // You can add custom logic here based on your requirements
    image.error = true;
  };

  const generatePdfCatalogue = async () => {
    setShowFormPopup((prev) => !prev);
  }

  const handleCatalogSubmit = async (e) => {
    e.preventDefault();
    // Set loading state
    setFormSubmissionStatus('loading');
    try {
      // Prepare the data to be sent in the request
      const requestData = {
        companyName,
        customerName,
        email,
        country,
        selectedImages, // Include any other relevant data
      };

      // Make the API call
      const response = await axios.post(
        `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/catalogue-user/add`,
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data) {
        // Handle success, e.g., show a success message
        setFormSubmissionStatus('success');
        // Optionally, reset the form fields or perform any other actions
        setCompanyName('');
        setCustomerName('');
        setEmail('');
        setCountry('');
        setShowFormPopup(false);

        generatePdf(response.data.res);
        setTimeout(() => {
          // Close the form pop-up after successful submission
          setShowSelected(false)
          setFormSubmissionStatus('idle');
        }, 1500);
      } else {
        // Handle API errors, set an error message or state
        setFormSubmissionStatus('error');
      }
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error('Error generating catalog:', error);
      setFormSubmissionStatus('error');
    }
  };

  // Add this function within your component or utility functions
  const copyToClipboard = (text) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  const generateLink = async (e) => {
    e.preventDefault();
    // Set loading state
    try {
      if (!customerName || !country || !email || !companyName) {
        return toast.error("Please fill out all field in the form");
      }
      setFormSubmissionStatus('loading');
      const requestData = {
        customerName,
        country,
        selectedImages
      }
      const slug = generateSlugByManualData(requestData);
      const encryptedSlug = encryptSlug(slug);
      const otp = generateRandomOTP(6);
      const currentDate = new Date();
      const expirationDate = new Date(currentDate);
      expirationDate.setFullYear(currentDate.getFullYear() + 1);
      // Prepare the data to be sent in the request
      const bodyData = {
        no_of_design: selectedImages.length,
        url: encryptedSlug,
        original_slug: slug,
        status: 1,
        otp,
        expire_date: expirationDate,
        no_of_reads: 5000,
        country_name: country,
        email: email,
        buyer_Name: customerName,
        designs: selectedImages,
        companyName: companyName,
        type: 1,
        move_to_manual: true
      };

      // Make the API call
      const response = await axios.post(
        `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/user-wish-list/manual-add-wishlist`,
        bodyData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data) {
        const { data } = response.data
        // Handle success, e.g., show a success message
        setFormSubmissionStatus('success');
        toast.success("The link has been generated successfully.");
        // Optionally, reset the form fields or perform any other actions
        setCompanyName('');
        setCustomerName('');
        setEmail('');
        setCountry('');
        setGenerateUrl(data.ops[0].url)
        setGenerateOtp(data.ops[0].otp)
        // setShowFormPopup(false);
        setTimeout(() => {
          // Close the form pop-up after successful submission
          setShowSelected(false)
          setFormSubmissionStatus('idle');
        }, 1500);
      } else {
        // Handle API errors, set an error message or state
        setFormSubmissionStatus('error');
      }
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error('Error in generate link:', error);
      setFormSubmissionStatus('error');
    }
  };

  const handleDesignSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!quantity && !price && !comment) {
        return toast.error("Please fill out at least one field in the form");
      }
      const storedEmail = localStorage.getItem('userEmail');
      let storedSelectedOption
      if (isEdit) {
        storedSelectedOption = selectedOption
      } else {
        storedSelectedOption = localStorage.getItem('selectedOption');
      }
      // Validate file type
      if (uploadImage) {
        const allowedFileTypes = ["image/jpeg", "image/png"]; // Add more types if needed
        if (!allowedFileTypes.includes(uploadImage.type)) {
          return toast.error("Invalid file type. Please upload an image.");
        }
      }

      // Create a FormData object
      const formData = new FormData();

      // Append form data
      formData.append('quantity', quantity);
      formData.append('price', price);
      formData.append('comment', comment);
      formData.append('uploadImage', uploadImage);
      formData.append('storedEmail', storedEmail);
      formData.append('slug', slug);
      formData.append('unit', storedSelectedOption);
      const addDesignDetailsString = JSON.stringify(addDesignDetails);
      formData.append('addDesignDetails', addDesignDetailsString);

      // Make the API call
      const response = await axios.post(
        `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/user-wish-list/update-design-data`,
        formData, // Adjust the payload as needed
      );
      if (response.data) {
        setPrice('');
        setQuantity('');
        setComment('');
        setUploadImage("")
        setEditDialogOpen(false);
        getAllWishList()
      }
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error('Error generating catalog:', error);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    // Perform OTP verification logic (e.g., make an API call)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/design/verify-otp`,
        { otp: otpInput, slug, user_email: emailInput }
      );
      if (response.data && response.data.status === 200) {
        setOtpVerified(true);
        setShowOtpPopup(false);
        incrementNoOfReads();
        localStorage.setItem('userEmail', emailInput);
        localStorage.setItem('otpSubmissionTime', new Date().getTime().toString());
        localStorage.setItem('verifiedSlug', slug);
        if (response.data.data.isAdmin) {
          localStorage.setItem('isAdmin', true);
        }
        setTimeout(() => {
          getAllWishList()
        }, 1500);
        // Set success message
        // setVerificationStatus({ type: 'success', message: 'OTP Verified Successfully!' });
      } else {
        setVerificationStatus({ type: 'error', message: response.data.message || 'OTP Verification Failed. Please try again.' });
      }
    } catch (error) {
      setVerificationStatus({ type: 'error', message: 'An error occurred. Please try again later.' });
    }
  };

  const generatePdf = async (data) => {
    setImageLoading(true);
    try {
      setTimeout(() => {
        if (selectedImages.length > 0) {

          const newWidth = 210; // Set the new width in millimeters
          const newHeight = 162; // Set the new height in millimeters
          const pdf = new jsPDF({
            orientation: 'l', // Portrait orientation
            unit: 'mm', // Measurement unit is millimeters
            format: [newWidth, newHeight] // Set page size to custom width and height
          });
          // const pdf = new jsPDF('l', 'mm', 'a4');

          const smallImgWidth = 20; // Width of each small image in mm
          const smallImgHeight = 20; // Height of each small image in mm
          const largeImgWidth = 139; // Width of the large image in mm
          const largeImgHeight = 130; // Height of the large image in mm
          const columnImgWidth = 65; // Width of each small image in the column in mm (adjusted)
          const columnImgHeight = 60; // Height of each small image in the column in mm (adjusted)
          const margin = 2; // Margin around each image in mm
          const imagesPerRow = 9; // Number of images to display in a row
          const imagesPerRowPS = 19; // Number of images to display in a row
          const pageWidth = pdf.internal.pageSize.width;
          const pageHeight = pdf.internal.pageSize.height;
          let additionalHeight = 0;
          let additionalWidth = 0;
          let bgColor = '#383838';
          let txtColor = '#FFFFFF';
          let fColor = '#000000';
          pdf.setFont('times', 'normal');
          const circleRadius = 4;

          // Set font color to white
          pdf.setTextColor(txtColor);  // White color
          pdf.setFillColor(bgColor);
          pdf.rect(0, 0, pageWidth, pageHeight, 'F'); // Fill rectangle with background color

          const imagesPerPage = 1;
          for (let j = 0; j < selectedImages.length; j++) {
            const image = selectedImages[j];
            let size = (image.height_width).split(' X ');
            let isBlackImage = false;
            if (image.color && (image.color).toUpperCase() === 'BLACK') {
              isBlackImage = true;
              // bgColor = '#D6D6D6';
              // txtColor = '#000000'
              // fColor = '#FFFFFF';
            } else {
              // fColor = '#000000';
            }

            if (size[0] !== size[1]) { // HERE IF HEight-Width is not same 
              // Add text to the top left corner
              // Calculate page and row
              const arrayLength = image.design_images ? image.design_images.length : 0;
              if (j !== 0) {
                pdf.addPage();
                pdf.setFillColor(bgColor); // White color
                pdf.rect(0, 0, pageWidth, pageHeight, 'F'); // Fill rectangle with background color
              }

              // Calculate y position for the next row after adding the column of small images
              const largeImgXPos = 0;
              const largeImgYPos = 0;
              const largeDesignImage = image.design_images[0]; // Adjust index
              if (largeDesignImage && largeDesignImage.filePath) {
                let calculatedLargeImgWidth = 73;
                additionalWidth = calculatedLargeImgWidth;
                // Apply size condition
                const largeImgData = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${largeDesignImage.filePath}`;
                if (Number(size[0]) > Number(size[1])) {
                  pdf.addImage(largeImgData, 'JPEG', largeImgXPos, largeImgYPos, calculatedLargeImgWidth, pageHeight,
                    null, 'FAST', -90);
                } else {
                  pdf.addImage(largeImgData, 'JPEG', largeImgXPos, largeImgYPos, calculatedLargeImgWidth, pageHeight);
                }
              }

              // Load the company logo
              const companyLogoWidth = 150 * pageWidth / 793;
              const companyLogoHeight = 60 * pageWidth / 793;
              const companyLogoXPos = margin + 5; // Adjust the position as needed
              const companyLogoYPos = margin + 5; // Adjust the position as needed
              let companyLogoData = `../../../../logo/Italica_logo2-removebg-preview.png`; // Replace with the actual path or URL of your company logo
              if (isBlackImage) {
                companyLogoData = `../../../../logo/Italica_Logo_W.png`; // Load while logo
                pdf.addImage(companyLogoData, 'PNG', companyLogoXPos + 3, companyLogoYPos + 3, companyLogoWidth - 4, companyLogoHeight - 4);
              } else {
                pdf.addImage(companyLogoData, 'PNG', companyLogoXPos, companyLogoYPos, companyLogoWidth, companyLogoHeight);
              }

              // Display 360 barcode link 
              const staticImgWidth = 18; // Width of the static image in mm
              const staticImgHeight = 18; // Height of the static image in mm

              const barcodeXPos = pageWidth - staticImgWidth - margin;
              const barcodeYPos = margin;
              const qrCodeSize = 18; // Adjust the size as needed
              let image3Dallign = 5;
              if (image.img_url_for_3d_view) {
                const qr = QRCode(0, 'L');
                qr.addData(image.img_url_for_3d_view);
                qr.make();
                const qrData = qr.createDataURL(4, 0);
                // Add a hyperlink annotation to the QR code
                const link = { url: image.img_url_for_3d_view, target: '_blank' }; // Adjust target as needed
                pdf.link(barcodeXPos, barcodeYPos, qrCodeSize, qrCodeSize, link);
                pdf.addImage(qrData, 'JPEG', barcodeXPos, barcodeYPos, qrCodeSize, qrCodeSize);
                image3Dallign = barcodeYPos + qrCodeSize;

                const staticImgXPos = barcodeXPos - (qrCodeSize + margin) - 2;
                const staticImgData = '`../../../../logo/360_image.png'; // Replace with the actual path or URL of your static image
                // Add a hyperlink annotation to the static image
                pdf.link(staticImgXPos, barcodeYPos, staticImgWidth, staticImgHeight, link);
                const logoSize = 12;
                pdf.addImage(staticImgData, 'PNG', staticImgXPos, barcodeYPos + 5, logoSize, logoSize - 2);
              }

              const randomYPos = margin + image3Dallign + 4; // Display RANDOM 
              pdf.setTextColor(txtColor); // Set text color to white
              pdf.setFontSize(10); // Set the font size

              // pdf.text(barcodeXPos, randomYPos, `RANDOM-${arrayLength}`, { align: 'left' });

              // Draw a circle with background color
              pdf.setFillColor(fColor); // Set background color to black
              pdf.circle(barcodeXPos, randomYPos - 1, circleRadius, 'F'); // Draw filled circle
              pdf.text(barcodeXPos, randomYPos, `${arrayLength}`, { align: 'center' });
              pdf.setFontSize(8.5); // Set the font size
              pdf.text(barcodeXPos + 5, randomYPos, 'RANDOM', { align: 'left' });

              // Calculate y position for the next row after adding the small images
              // Display two small images in a column
              let xPos = margin + additionalWidth + 18;
              let xPosTmp = xPos;
              let yPos = margin;
              let newRow = 0;
              for (let i = 0; i < Math.min(imagesPerRowPS, image.design_images.length); i++) {
                let yMargin = 0, xMargin = 0;
                const designImage = image.design_images[i + 1]; // Adjust index to get the correct images
                if (designImage && designImage.filePath) {
                  let imgWidth = 15;
                  let imgHeight = 30;
                  if (i === 0) {
                    imgWidth = imgWidth * 2;
                    imgHeight = imgHeight * 2;
                  } else {
                    if (newRow === 0) {
                      xMargin = imgWidth;
                      newRow = 0;
                    }
                    yMargin = imgHeight;
                  }
                  xPos += imgWidth + margin;

                  if (i === 0) {
                    imgWidth += 2;
                  } else if (i === 1) {
                    xPos += 2;
                  }

                  if ((xPos + xMargin + imgWidth) > pageWidth) {
                    yPos += imgHeight + margin;
                    xPos = xPosTmp;
                    xMargin = 0;
                    xPos += (imgWidth * 2) + margin;
                    newRow = 1;
                  }

                  // Apply size condition
                  const imgData = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${designImage.filePath}`;
                  if (Number(size[0]) > Number(size[1])) {
                    pdf.addImage(imgData, 'JPEG', xPos + xMargin, yPos + yMargin, imgWidth, imgHeight, null, 'FAST', -90);
                  } else {
                    pdf.addImage(imgData, 'JPEG', xPos + xMargin, yPos + yMargin, imgWidth, imgHeight);
                  }
                }
              }

              // Footer
              let yFooter = 41;
              // let yFooterSpace = pageHeight - yFooter;
              let xPos1 = margin + additionalWidth;
              pdf.setFontSize(12);
              let designNm = image.design_name;
              if (image.design_name !== image.buyer_design_name) {
                const buyerDesignName = image.buyer_design_name ? ` | ${image.buyer_design_name}` : '';
                designNm = `${image.design_name}${buyerDesignName}`
              }
              let lastY4 = 0;
              if (designNm !== '') {
                const splitInfo6 = pdf.splitTextToSize(designNm, 45);
                pdf.text(splitInfo6, xPos1, pageHeight - (yFooter -= lastY4));
                const textHht = pdf.getTextDimensions(splitInfo6);
                lastY4 += textHht.h;
              }
              yFooter -= lastY4;

              pdf.setFontSize(10);
              pdf.text(image.height_width + ' mm', xPos1, pageHeight - (yFooter -= 5));
              pdf.text('THIK: ' + image.thickness_mm + 'mm', xPos1, pageHeight - (yFooter -= 5));

              const tmpFinish = image.finish_id && image.finish_id.map(x => x.finish_name);
              const tmpStr = tmpFinish.join(', '); // Join the finish names with commas
              pdf.text(tmpStr, xPos1, pageHeight - (yFooter -= 5));

              pdf.setTextColor(txtColor); // White color
              // Here if added addtional details 
              if (!isBlackImage && image.design_images.length > 15) {
                pdf.setTextColor(0, 0, 0); // Set text color to Black
              }

              if (image.quantity || image.price || image.comment || image.fileName) {
                pdf.setFontSize(10);
                // Set text color
                const marginBottom = 1; // Adjust the bottom margin as needed
                let xElementDetails = xPos1 + 20;

                // Set line color for the box
                pdf.setDrawColor(0, 0, 0); // Black
                // Quantity
                if (image.quantity) {
                  pdf.text(`${image.unit?.toUpperCase() || "Container"}: ${image.quantity}`, xElementDetails, pageHeight - margin - marginBottom - 10);
                }
                // Price
                if (image.price) {
                  pdf.text(`Price: ${image.price}`, xElementDetails + 20, pageHeight - margin - marginBottom - 10);
                }
                // Comment
                if (image.comment) {
                  const commentX = xElementDetails + 45;
                  const commentY = pageHeight - margin - marginBottom - 10;
                  const commentWidth = 70; // Adjust the width as needed
                  const commentLines = pdf.splitTextToSize(`Comment: ${image.comment}`, commentWidth);

                  pdf.text(commentLines, commentX, commentY);
                }
                if (image.fileName) {
                  const linkText = `Open File: ${image.fileName}`;
                  const linkX = xElementDetails + 45; // X-coordinate of the link
                  const linkY = pageHeight - margin - marginBottom - 10; // Y-coordinate of the link
                  const linkWidth = pdf.getStringUnitWidth(linkText) * pdf.internal.getFontSize(); // Width of the link
                  const linkHeight = pdf.internal.getLineHeight(); // Height of the link

                  // Add the link
                  const fileUrl = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_UPLOAD_BASE_URL}/${image.fileName}`;
                  const link = { url: fileUrl, text: linkText, target: '_blank' };
                  pdf.link(linkX, linkY, linkWidth, linkHeight, link);

                  // Add the clickable text
                  const clickText = 'Click here to open the attachment';
                  pdf.textWithLink(clickText, linkX + 81, linkY, { url: fileUrl, target: '_blank' });
                }
              }

            } else { // IF IMAGE HEIGHT-width SAME 
              // Add text to the top left corner
              // Calculate page and row

              // Load the company logo
              const companyLogoWidth = 150 * pageWidth / 793;
              const companyLogoHeight = 60 * pageWidth / 793;
              const companyLogoXPos = pageWidth - companyLogoWidth - margin; // Adjust the position as needed
              const companyLogoYPos = margin; // Adjust the position as needed
              // const companyLogoData = `../../../../logo/Italica_logo2-removebg-preview.png`; // Replace with the actual path or URL of your company logo
              // pdf.addImage(companyLogoData, 'PNG', companyLogoXPos, companyLogoYPos, companyLogoWidth, companyLogoHeight);

              if (j === 0) {
                pdf.setFillColor(bgColor); // White color
                pdf.rect(0, 0, pageWidth, pageHeight, 'F'); // Fill rectangle with background color
                const companyLogoData = `../../../../logo/Italica_Logo_W.png`; // Load while logo
                pdf.addImage(companyLogoData, 'PNG', companyLogoXPos + 3, companyLogoYPos + 3, companyLogoWidth - 4, companyLogoHeight - 4);
              }

              const pageIndex = Math.floor(j / imagesPerPage);
              const rowIndex = j % imagesPerPage;
              const textXPos = margin + 5;
              const textYPos = margin + 2 + j * 210 + pageIndex * pageHeight;;
              const textFontSize = 14;
              pdf.setDrawColor(txtColor); // White
              pdf.setTextColor(txtColor); // White

              pdf.setLineWidth(1);
              pdf.setFontSize(textFontSize);
              if (image.design_name === image.buyer_design_name) {
                pdf.text(image.design_name, textXPos + 10, textYPos + 5);
              } else {
                const buyerDesignName = image.buyer_design_name ? ` | ${image.buyer_design_name}` : '';
                pdf.text(`${image.design_name}${buyerDesignName}`, textXPos + 10, textYPos + 5);
              }

              const tmpFinish = image.finish_id && image.finish_id.map(x => x.finish_name);
              const tmpStr = '# ' + tmpFinish.join(', '); // Join the finish names with commas
              pdf.setFontSize(10);
              pdf.text(tmpStr, textXPos + 10, textYPos + 9.5);

              pdf.setLineWidth(0.5);
              pdf.line(textXPos, textYPos + 18, textXPos + 8, textYPos + 18);
              pdf.line(textXPos, textYPos, textXPos, textYPos + 18);
              pdf.line(textXPos, textYPos, textXPos + 8, textYPos);
              pdf.setLineDash([9.5, 7], 0);
              pdf.line(textXPos + 8, textYPos, textXPos + 8, textYPos + 18);
              pdf.setFontSize(12);
              const thick = image.thickness_mm ? ' | THIK: ' + image.thickness_mm + 'mm' : '';
              pdf.text(convertToMMFormat(image.height_width) + thick, textXPos + 5, textYPos + 14.5);

              // Check if the page is not the first one, then add a new page
              if (j > 0 && rowIndex === 0) {
                pdf.addPage();
                pdf.setFillColor(bgColor); // White color
                pdf.rect(0, 0, pageWidth, pageHeight, 'F'); // Fill rectangle with background color
                pdf.setTextColor(txtColor);  // White color

                // Add text to the top left corner
                const textXPos = margin + 5;
                const textYPos = margin + 2;
                const textFontSize = 14;
                pdf.setLineWidth(1);

                pdf.setFontSize(textFontSize);
                if (image.design_name === image.buyer_design_name) {
                  pdf.text(image.design_name, textXPos + 10, textYPos + 5);
                } else {
                  const buyerDesignName = image.buyer_design_name ? ` | ${image.buyer_design_name}` : '';
                  pdf.text(`${image.design_name}${buyerDesignName}`, textXPos + 10, textYPos + 5);
                }

                const tmpFinish = image.finish_id && image.finish_id.map(x => x.finish_name);
                const tmpStr = '# ' + tmpFinish.join(', '); // Join the finish names with commas
                pdf.setFontSize(10);
                pdf.text(tmpStr, textXPos + 10, textYPos + 9.5);

                pdf.setLineWidth(0.5);
                pdf.line(textXPos, textYPos + 18, textXPos + 8, textYPos + 18);
                pdf.line(textXPos, textYPos, textXPos, textYPos + 18);
                pdf.line(textXPos, textYPos, textXPos + 8, textYPos);
                pdf.setLineDash([9.5, 7], 0);
                pdf.line(textXPos + 8, textYPos, textXPos + 8, textYPos + 18);
                pdf.setFontSize(12);
                const thick = image.thickness_mm ? ' | THIK: ' + image.thickness_mm + 'mm' : '';
                pdf.text(convertToMMFormat(image.height_width) + thick, textXPos + 5, textYPos + 14.5);

                const arrayLengthXPos = 105;
                const arrayLengthYPos = 16;
                const arrayLength = image.design_images ? image.design_images.length : 0;

                // Draw a circle with background color
                pdf.setFillColor(0); // Set background color to black
                pdf.circle(105, 15, circleRadius, 'F'); // Draw filled circle
                // Text for array length
                pdf.setTextColor(txtColor);  // White color
                pdf.setFontSize(10); // Set the font size
                pdf.text(arrayLengthXPos, arrayLengthYPos, `${arrayLength}`, { align: 'center' });
                pdf.setFontSize(8.5); // Set the font size
                pdf.text(99, 23, 'RANDOM', { align: 'left' });

                if (!isBlackImage) {
                  pdf.setTextColor(0, 0, 0); // Set text color to Black
                } else {
                  pdf.setTextColor(txtColor);  // White color
                }
                if (image.quantity || image.price || image.comment || image.fileName) {
                  pdf.setFontSize(10);
                  // Set text color
                  const marginBottom = 1; // Adjust the bottom margin as needed
                  let xElementDetails = 80;
                  // Set line color for the box
                  pdf.setDrawColor(0, 0, 0); // Black
                  // Quantity
                  if (image.quantity) {
                    pdf.text(`${image.unit?.toUpperCase() || "Container"}: ${image.quantity}`, xElementDetails, pageHeight - margin - marginBottom - 10);
                  }
                  // Price
                  if (image.price) {
                    pdf.text(`Price: ${image.price}`, xElementDetails + 20, pageHeight - margin - marginBottom - 10);
                  }
                  // Comment
                  if (image.comment) {
                    const commentX = xElementDetails + 45;
                    const commentY = pageHeight - margin - marginBottom - 10;
                    const commentWidth = 70; // Adjust the width as needed
                    const commentLines = pdf.splitTextToSize(`Comment: ${image.comment}`, commentWidth);

                    pdf.text(commentLines, commentX, commentY);
                  }

                  if (image.fileName) {
                    const linkText = `Open File: ${image.fileName}`;
                    const linkX = xElementDetails + 45; // X-coordinate of the link
                    const linkY = pageHeight - margin - marginBottom - 10; // Y-coordinate of the link
                    const linkWidth = pdf.getStringUnitWidth(linkText) * pdf.internal.getFontSize(); // Width of the link
                    const linkHeight = pdf.internal.getLineHeight(); // Height of the link

                    // Add the link
                    const fileUrl = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_UPLOAD_BASE_URL}/${image.fileName}`;
                    const link = { url: fileUrl, text: linkText, target: '_blank' };
                    pdf.link(linkX, linkY, linkWidth, linkHeight, link);

                    // Add the clickable text
                    const clickText = 'Click here to open the attachment';
                    pdf.textWithLink(clickText, linkX + 81, linkY, { url: fileUrl, target: '_blank' });
                  }
                }

                // Load the company logo
                const companyLogoWidth = 150 * pageWidth / 793;
                const companyLogoHeight = 60 * pageWidth / 793;
                const companyLogoXPos = pageWidth - companyLogoWidth - margin; // Adjust the position as needed
                const companyLogoYPos = margin; // Adjust the position as needed
                // const companyLogoData = `../../../../logo/Italica_logo2-removebg-preview.png`; // Replace with the actual path or URL of your company logo
                // pdf.addImage(companyLogoData, 'PNG', companyLogoXPos, companyLogoYPos, companyLogoWidth, companyLogoHeight);
                const companyLogoData = `../../../../logo/Italica_Logo_W.png`; // Load while logo
                pdf.addImage(companyLogoData, 'PNG', companyLogoXPos + 3, companyLogoYPos + 3, companyLogoWidth - 4, companyLogoHeight - 4)
              }

              const arrayLengthXPos = 105;
              const arrayLengthYPos = 16;
              const arrayLength = image.design_images ? image.design_images.length : 0;

              // Draw a circle with background color
              pdf.setFillColor(0); // Set background color to black
              pdf.circle(105, 15, circleRadius, 'F'); // Draw filled circle
              // Text for array length
              pdf.setTextColor(txtColor); // Set text color to white
              pdf.setFontSize(10); // Set the font size
              pdf.text(arrayLengthXPos, arrayLengthYPos, `${arrayLength}`, { align: 'center' });
              pdf.setFontSize(8.5); // Set the font size
              pdf.text(99, 23, 'RANDOM', { align: 'left' });
              pdf.setFillColor(bgColor); // Set background color
              if (!isBlackImage) {
                pdf.setTextColor(0, 0, 0); // Set text color to Black
              } else {
                pdf.setTextColor(txtColor);  // White color
              }
              if (image.quantity || image.price || image.comment || image.fileName) {
                pdf.setFontSize(10);
                // Set text color
                const marginBottom = 1; // Adjust the bottom margin as needed
                let xElementDetails = 80;
                // Set line color for the box
                pdf.setDrawColor(0, 0, 0); // Black
                // Quantity
                if (image.quantity) {
                  pdf.text(`${image.unit?.toUpperCase() || "Container"}: ${image.quantity}`, xElementDetails, pageHeight - margin - marginBottom - 10);
                }
                // Price
                if (image.price) {
                  pdf.text(`Price: ${image.price}`, xElementDetails + 20, pageHeight - margin - marginBottom - 10);
                }
                // Comment
                if (image.comment) {
                  const commentX = xElementDetails + 45;
                  const commentY = pageHeight - margin - marginBottom - 10;
                  const commentWidth = 70; // Adjust the width as needed
                  const commentLines = pdf.splitTextToSize(`Comment: ${image.comment}`, commentWidth);
                  pdf.text(commentLines, commentX, commentY);
                }
                if (image.fileName) {
                  const linkText = `Open File: ${image.fileName}`;
                  const linkX = xElementDetails + 45; // X-coordinate of the link
                  const linkY = pageHeight - margin - marginBottom - 10; // Y-coordinate of the link
                  const linkWidth = pdf.getStringUnitWidth(linkText) * pdf.internal.getFontSize(); // Width of the link
                  const linkHeight = pdf.internal.getLineHeight(); // Height of the link

                  // Add the link
                  const fileUrl = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_UPLOAD_BASE_URL}/${image.fileName}`;
                  const link = { url: fileUrl, text: linkText, target: '_blank' };
                  pdf.link(linkX, linkY, linkWidth, linkHeight, link);

                  // Add the clickable text
                  const clickText = 'Click here to open the attachment';
                  pdf.textWithLink(clickText, linkX + 81, linkY, { url: fileUrl, target: '_blank' });
                }
              }

              let x = margin;
              // let y = margin + 24;
              let y = margin + 6;

              // Display six small images in a row
              let topGalleryImagesH = 0;
              if (image.design_images.length !== 3) {
                for (let i = 0; i < Math.min(imagesPerRow, image.design_images.length); i++) {
                  const designImage = image.design_images[i];
                  let size = image.height_width;
                  size = size.split(' X ');
                  if (designImage && designImage.filePath) {
                    if (size[0] === size[1]) {
                      additionalHeight = 0;
                      topGalleryImagesH = 22;
                    } else if (Number(size[0]) < Number(size[1])) {
                      additionalHeight = 10;
                      topGalleryImagesH = 22;
                    } else if (Number(size[0]) > Number(size[1])) {
                      additionalHeight = -8;
                      topGalleryImagesH = 8;
                    } else {
                      additionalHeight = 0;
                      topGalleryImagesH = 0;
                    }
                  }
                }
              } else {
                additionalHeight = 0;
              }

              // Display the barcode next to the row of small images
              const barcodeXPos = x + (imagesPerRow + 1) * (smallImgWidth + margin);
              const barcodeYPos = y;
              const qrCodeSize = 20; // Adjust the size as needed
              if (image.img_url_for_3d_view) {
                const qr = QRCode(0, 'L');
                qr.addData(image.img_url_for_3d_view);
                qr.make();
                const qrData = qr.createDataURL(4, 0);

                // Add a hyperlink annotation to the QR code
                const link = { url: image.img_url_for_3d_view, target: '_blank' }; // Adjust target as needed
                pdf.link(barcodeXPos, barcodeYPos, qrCodeSize, qrCodeSize, link);
                pdf.addImage(qrData, 'JPEG', barcodeXPos, barcodeYPos, qrCodeSize, qrCodeSize);

                // Display a static image above the barcode
                const staticImgWidth = 20; // Width of the static image in mm
                const staticImgHeight = 20; // Height of the static image in mm
                const staticImgXPos = 155.5; // Adjust the position as needed
                const staticImgYPos = barcodeYPos - staticImgHeight + 5; // Adjust the position as needed
                const staticImgData = '`../../../../logo/360_image.png'; // Replace with the actual path or URL of your static image
                // Add a hyperlink annotation to the static image
                const staticImgLink = { url: image.img_url_for_3d_view, target: '_blank' }; // Replace with the actual URL of your static image
                pdf.link(staticImgXPos, staticImgYPos, staticImgWidth, staticImgHeight, staticImgLink);
                pdf.addImage(staticImgData, 'PNG', staticImgXPos, staticImgYPos, staticImgWidth, staticImgHeight);
              }

              let loopC = 1, addH = 0, on2Img = 0;;
              if (image.design_images.length <= 3) {
                loopC = 2;
                on2Img = 4;
              } else {
                addH = 4;
              }
              let yPoss = 0;
              y += smallImgHeight + margin;
              for (let i = 0; i < loopC; i++) {
                const xPos = x;
                let yPos = y + i * (columnImgHeight + margin) + additionalHeight + (i * on2Img);
                let gapBetweenImages = 0;
                const designImage = image.design_images[i + 1]; // Adjust index to get the correct images
                if (designImage && designImage.filePath) {
                  const imgWidth = columnImgWidth; // Adjust width as needed
                  const imgHeight = columnImgHeight + addH + on2Img; // Adjust height as needed
                  yPos += i * gapBetweenImages;
                  const imgData = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${designImage.filePath}`;
                  pdf.addImage(imgData, 'JPEG', xPos, yPos, imgWidth, imgHeight);
                  yPoss = yPos + i * gapBetweenImages + imgHeight + margin;
                }
              }

              const largeImgXPos = x + columnImgWidth + margin;
              let yAdd = 0;
              if (image.design_images.length > 3) {
                let xPos = x;
                for (let i = 0; i < Math.min(imagesPerRow, image.design_images.length); i++) {
                  let yPos = yPoss;
                  const designImage = image.design_images[3 + i];
                  let size = image.height_width;
                  size = size.split(' X ');
                  let newRow = 0;
                  if (i !== 0 && newRow === 0) {
                    xPos += (smallImgWidth + margin + 0.3);
                  }
                  if ((xPos) > 66) {
                    yAdd += 20 + margin;
                    xPos = x;
                    newRow = 1;
                  }

                  if (designImage && designImage.filePath) {
                    const imgData = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${designImage.filePath}`;
                    pdf.addImage(imgData, 'JPEG', xPos, yPos + yAdd, 20, 20);
                  }
                }
              }

              // Calculate y position for the next row after adding the column of small images
              const columnHeight = 2 * columnImgHeight + margin;
              // Display one large image to the side of the column
              const largeImgYPos = y + additionalHeight;
              const largeDesignImage = image.design_images[0]; // Adjust index
              if (largeDesignImage && largeDesignImage.filePath) {
                let size = image.height_width;
                size = size.split(' X ');
                let calculatedLargeImgWidth = largeImgWidth;
                let calculatedLargeImgHeight = largeImgHeight;

                // Apply size condition
                if (size[0] === size[1]) {
                  calculatedLargeImgWidth = largeImgWidth; // Adjust width as needed
                  calculatedLargeImgHeight = largeImgHeight; // Adjust height as needed
                  additionalHeight = 0
                } else if (Number(size[0]) < Number(size[1])) {
                  calculatedLargeImgWidth = largeImgWidth;
                  calculatedLargeImgHeight = 152;
                  additionalHeight = 50
                } else if (Number(size[0]) > Number(size[1])) {
                  calculatedLargeImgWidth = largeImgWidth;
                  calculatedLargeImgHeight = 97;
                  additionalHeight = -41
                } else {
                  calculatedLargeImgWidth = largeImgWidth;
                  calculatedLargeImgHeight = largeImgHeight;
                }
                const largeImgData = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${largeDesignImage.filePath}`;
                pdf.addImage(largeImgData, 'JPEG', largeImgXPos, largeImgYPos, calculatedLargeImgWidth, calculatedLargeImgHeight);
              }

              // Calculate y position for the next row after adding the large image
              y += columnHeight + margin;

              if (!isBlackImage) {
                pdf.setTextColor(0, 0, 0); // Set text color to Black
              } else {
                pdf.setTextColor(txtColor);  // White color
              }
              if (image.quantity || image.price || image.comment || image.fileName) {
                pdf.setFontSize(10);
                // Set text color
                const marginBottom = 1; // Adjust the bottom margin as needed
                let xElementDetails = 80;

                // Set line color for the box
                pdf.setDrawColor(0, 0, 0); // Black
                // Quantity
                if (image.quantity) {
                  pdf.text(`${image.unit?.toUpperCase() || "Container"}: ${image.quantity}`, xElementDetails, pageHeight - margin - marginBottom - 10);
                }
                // Price
                if (image.price) {
                  pdf.text(`Price: ${image.price}`, xElementDetails + 20, pageHeight - margin - marginBottom - 10);
                }
                // Comment
                if (image.comment) {
                  const commentX = xElementDetails + 45;
                  const commentY = pageHeight - margin - marginBottom - 10;
                  const commentWidth = 70; // Adjust the width as needed
                  const commentLines = pdf.splitTextToSize(`Comment: ${image.comment}`, commentWidth);
                  pdf.text(commentLines, commentX, commentY);
                }
                if (image.fileName) {
                  const linkText = `Open File: ${image.fileName}`;
                  const linkX = xElementDetails + 45; // X-coordinate of the link
                  const linkY = pageHeight - margin - marginBottom - 10; // Y-coordinate of the link
                  const linkWidth = pdf.getStringUnitWidth(linkText) * pdf.internal.getFontSize(); // Width of the link
                  const linkHeight = pdf.internal.getLineHeight(); // Height of the link

                  // Add the link
                  const fileUrl = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_UPLOAD_BASE_URL}/${image.fileName}`;
                  const link = { url: fileUrl, text: linkText, target: '_blank' };
                  pdf.link(linkX, linkY, linkWidth, linkHeight, link);

                  // Add the clickable text
                  const clickText = 'Click here to open the attachment';
                  pdf.textWithLink(clickText, linkX + 81, linkY, { url: fileUrl, target: '_blank' });
                }
              }
            }

            // Add a new page for 3D Image
            pdf.addPage();
            pdf.setFillColor(bgColor); // White color
            pdf.rect(0, 0, pageWidth, pageHeight, 'F'); // Fill rectangle with background color
            const imageWidth = pageWidth - 12; // Fixed width for each image //  pageWidth - 4
            const imageHeight = pageHeight - 12; // Fixed height for each image
            let xPos = 6; // margin
            let yPos = 6;
            const attachmentImgData = image.design_image && image.design_image !== '' ?
              `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}/${image.design_image}` :
              `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${image.design_images[0].filePath}`;
            pdf.addImage(attachmentImgData, 'JPEG', xPos, yPos, imageWidth, imageHeight);
          }

          // Check if there is attachment data
          if (attachmentData && attachmentData.length > 0) {
            const imagesPerRow = 3; // Number of images to display in a row
            const imagesPerPage = 12; // Number of images to display per page

            for (let page = 0; page < Math.ceil(attachmentData.length / imagesPerPage); page++) {
              // Add a new page for attachment data
              pdf.addPage();
              pdf.setFillColor(bgColor); // White color
              pdf.rect(0, 0, pageWidth, pageHeight, 'F'); // Fill rectangle with background color
              pdf.setTextColor(txtColor); // Set text color to white

              // Add title for attachment data
              const imageWidth = 60; // Fixed width for each image
              const imageHeight = 40; // Fixed height for each image
              const rowHeight = 60; // Height of each row
              const textWidth = 65;
              const gapWidth = 12; // Width of the gap between images
              // Display attachment data on the current page
              let xPos = margin;
              let yPos = margin + 20;

              for (let i = page * imagesPerPage; i < Math.min((page + 1) * imagesPerPage, attachmentData.length); i++) {
                const attachmentImage = attachmentData[i];
                // Wrap the message text to fit within the specified width
                const wrappedText = pdf.splitTextToSize(attachmentImage.message, textWidth);

                // Add wrapped message text
                pdf.setFontSize(12);
                pdf.text(wrappedText, xPos, yPos);

                // Add image
                const attachmentImgData = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_UPLOAD_BASE_URL}/attachment/${attachmentImage.image_name}`;
                pdf.addImage(attachmentImgData, 'JPEG', xPos, yPos + 10, imageWidth, imageHeight);

                // Move to the next column with gap
                xPos += imageWidth + gapWidth;

                // Check if a new row needs to be started
                if (i % imagesPerRow === imagesPerRow - 1) {
                  xPos = margin;
                  yPos += rowHeight;
                }
              }
            }
          }

          // Save or download the PDF
          pdf.save(`italica-${data.customerName.replace(/\s+/g, "-")}-${data.country.replace(/\s+/g, "-")}.pdf`);
          setImageLoading(false);
        }
      }, 500);
    } catch (error) {
      console.error('Error generating PDF:', error);
      setImageLoading(false); // Hide loader in case of an error
    }
  };

  const optionStyle = {
    //width: '100px', // Adjust width as needed
    display: 'flex',
    alignItems: 'center',
  };

  const handleDownloadCatalogue = async (data) => {
    setImageLoading(true);
    try {
      const selectedImages = [data];
      setTimeout(() => {
        if (selectedImages.length > 0) {

          const newWidth = 210; // Set the new width in millimeters
          const newHeight = 162; // Set the new height in millimeters
          const pdf = new jsPDF({
            orientation: 'l', // Portrait orientation
            unit: 'mm', // Measurement unit is millimeters
            format: [newWidth, newHeight] // Set page size to custom width and height
          });
          // const pdf = new jsPDF('l', 'mm', 'a4');

          const smallImgWidth = 20; // Width of each small image in mm
          const smallImgHeight = 20; // Height of each small image in mm
          const largeImgWidth = 139; // Width of the large image in mm
          const largeImgHeight = 130; // Height of the large image in mm
          const columnImgWidth = 65; // Width of each small image in the column in mm (adjusted)
          const columnImgHeight = 60; // Height of each small image in the column in mm (adjusted)
          const margin = 2; // Margin around each image in mm
          const imagesPerRow = 9; // Number of images to display in a row
          const imagesPerRowPS = 19; // Number of images to display in a row
          const pageWidth = pdf.internal.pageSize.width;
          const pageHeight = pdf.internal.pageSize.height;
          let additionalHeight = 0;
          let additionalWidth = 0;
          let bgColor = '#383838';
          let txtColor = '#FFFFFF';
          let fColor = '#000000';
          pdf.setFont('times', 'normal');
          const circleRadius = 4;

          // Set font color to white
          pdf.setTextColor(txtColor);  // White color
          pdf.setFillColor(bgColor);
          pdf.rect(0, 0, pageWidth, pageHeight, 'F'); // Fill rectangle with background color

          const imagesPerPage = 1;
          for (let j = 0; j < selectedImages.length; j++) {
            const image = selectedImages[j];
            let size = (image.height_width).split(' X ');
            let isBlackImage = false;
            if (image.color && (image.color).toUpperCase() === 'BLACK') {
              isBlackImage = true;
              // bgColor = '#D6D6D6';
              // txtColor = '#000000'
              // fColor = '#FFFFFF';
            } else {
              // fColor = '#000000';
            }

            if (size[0] !== size[1]) { // HERE IF HEight-Width is not same 
              // Add text to the top left corner
              // Calculate page and row
              const arrayLength = image.design_images ? image.design_images.length : 0;
              if (j !== 0) {
                pdf.addPage();
                pdf.setFillColor(bgColor); // White color
                pdf.rect(0, 0, pageWidth, pageHeight, 'F'); // Fill rectangle with background color
              }

              // Calculate y position for the next row after adding the column of small images
              const largeImgXPos = 0;
              const largeImgYPos = 0;
              const largeDesignImage = image.design_images[0]; // Adjust index
              if (largeDesignImage && largeDesignImage.filePath) {
                let calculatedLargeImgWidth = 73;
                additionalWidth = calculatedLargeImgWidth;
                // Apply size condition
                const largeImgData = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${largeDesignImage.filePath}`;
                if (Number(size[0]) > Number(size[1])) {
                  pdf.addImage(largeImgData, 'JPEG', largeImgXPos, largeImgYPos, calculatedLargeImgWidth, pageHeight,
                    null, 'FAST', -90);
                } else {
                  pdf.addImage(largeImgData, 'JPEG', largeImgXPos, largeImgYPos, calculatedLargeImgWidth, pageHeight);
                }
              }

              // Load the company logo
              const companyLogoWidth = 150 * pageWidth / 793;
              const companyLogoHeight = 60 * pageWidth / 793;
              const companyLogoXPos = margin + 5; // Adjust the position as needed
              const companyLogoYPos = margin + 5; // Adjust the position as needed
              let companyLogoData = `../../../../logo/Italica_logo2-removebg-preview.png`; // Replace with the actual path or URL of your company logo
              if (isBlackImage) {
                companyLogoData = `../../../../logo/Italica_Logo_W.png`; // Load while logo
                pdf.addImage(companyLogoData, 'PNG', companyLogoXPos + 3, companyLogoYPos + 3, companyLogoWidth - 4, companyLogoHeight - 4);
              } else {
                pdf.addImage(companyLogoData, 'PNG', companyLogoXPos, companyLogoYPos, companyLogoWidth, companyLogoHeight);
              }

              // Display 360 barcode link 
              const staticImgWidth = 18; // Width of the static image in mm
              const staticImgHeight = 18; // Height of the static image in mm

              const barcodeXPos = pageWidth - staticImgWidth - margin;
              const barcodeYPos = margin;
              const qrCodeSize = 18; // Adjust the size as needed
              let image3Dallign = 5;
              if (image.img_url_for_3d_view) {
                const qr = QRCode(0, 'L');
                qr.addData(image.img_url_for_3d_view);
                qr.make();
                const qrData = qr.createDataURL(4, 0);
                // Add a hyperlink annotation to the QR code
                const link = { url: image.img_url_for_3d_view, target: '_blank' }; // Adjust target as needed
                pdf.link(barcodeXPos, barcodeYPos, qrCodeSize, qrCodeSize, link);
                pdf.addImage(qrData, 'JPEG', barcodeXPos, barcodeYPos, qrCodeSize, qrCodeSize);
                image3Dallign = barcodeYPos + qrCodeSize;

                const staticImgXPos = barcodeXPos - (qrCodeSize + margin) - 2;
                const staticImgData = '`../../../../logo/360_image.png'; // Replace with the actual path or URL of your static image
                // Add a hyperlink annotation to the static image
                pdf.link(staticImgXPos, barcodeYPos, staticImgWidth, staticImgHeight, link);
                const logoSize = 12;
                pdf.addImage(staticImgData, 'PNG', staticImgXPos, barcodeYPos + 5, logoSize, logoSize - 2);
              }

              const randomYPos = margin + image3Dallign + 4; // Display RANDOM 
              pdf.setTextColor(txtColor); // Set text color to white
              pdf.setFontSize(10); // Set the font size

              // pdf.text(barcodeXPos, randomYPos, `RANDOM-${arrayLength}`, { align: 'left' });

              // Draw a circle with background color
              pdf.setFillColor(fColor); // Set background color to black
              pdf.circle(barcodeXPos, randomYPos - 1, circleRadius, 'F'); // Draw filled circle
              pdf.text(barcodeXPos, randomYPos, `${arrayLength}`, { align: 'center' });
              pdf.setFontSize(8.5); // Set the font size
              pdf.text(barcodeXPos + 5, randomYPos, 'RANDOM', { align: 'left' });

              // Calculate y position for the next row after adding the small images
              // Display two small images in a column
              let xPos = margin + additionalWidth + 18;
              let xPosTmp = xPos;
              let yPos = margin;
              let newRow = 0;
              for (let i = 0; i < Math.min(imagesPerRowPS, image.design_images.length); i++) {
                let yMargin = 0, xMargin = 0;
                const designImage = image.design_images[i + 1]; // Adjust index to get the correct images
                if (designImage && designImage.filePath) {
                  let imgWidth = 15;
                  let imgHeight = 30;
                  if (i === 0) {
                    imgWidth = imgWidth * 2;
                    imgHeight = imgHeight * 2;
                  } else {
                    if (newRow === 0) {
                      xMargin = imgWidth;
                      newRow = 0;
                    }
                    yMargin = imgHeight;
                  }
                  xPos += imgWidth + margin;

                  if (i === 0) {
                    imgWidth += 2;
                  } else if (i === 1) {
                    xPos += 2;
                  }

                  if ((xPos + xMargin + imgWidth) > pageWidth) {
                    yPos += imgHeight + margin;
                    xPos = xPosTmp;
                    xMargin = 0;
                    xPos += (imgWidth * 2) + margin;
                    newRow = 1;
                  }

                  // Apply size condition
                  const imgData = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${designImage.filePath}`;
                  if (Number(size[0]) > Number(size[1])) {
                    pdf.addImage(imgData, 'JPEG', xPos + xMargin, yPos + yMargin, imgWidth, imgHeight, null, 'FAST', -90);
                  } else {
                    pdf.addImage(imgData, 'JPEG', xPos + xMargin, yPos + yMargin, imgWidth, imgHeight);
                  }
                }
              }

              // Footer
              let yFooter = 41;
              // let yFooterSpace = pageHeight - yFooter;
              let xPos1 = margin + additionalWidth;
              pdf.setFontSize(12);
              let designNm = image.design_name;
              if (image.design_name !== image.buyer_design_name) {
                const buyerDesignName = image.buyer_design_name ? ` | ${image.buyer_design_name}` : '';
                designNm = `${image.design_name}${buyerDesignName}`
              }
              let lastY4 = 0;
              if (designNm !== '') {
                const splitInfo6 = pdf.splitTextToSize(designNm, 45);
                pdf.text(splitInfo6, xPos1, pageHeight - (yFooter -= lastY4));
                const textHht = pdf.getTextDimensions(splitInfo6);
                lastY4 += textHht.h;
              }
              yFooter -= lastY4;

              pdf.setFontSize(10);
              pdf.text(image.height_width + ' mm', xPos1, pageHeight - (yFooter -= 5));
              pdf.text('THIK: ' + image.thickness_mm + 'mm', xPos1, pageHeight - (yFooter -= 5));

              const tmpFinish = image.finish_id && image.finish_id.map(x => x.finish_name);
              const tmpStr = tmpFinish.join(', '); // Join the finish names with commas
              pdf.text(tmpStr, xPos1, pageHeight - (yFooter -= 5));

              pdf.setTextColor(txtColor); // Set text color to Black

              // Here if added addtional details 
              if (!isBlackImage && image.design_images.length > 15) {
                pdf.setTextColor(0, 0, 0); // Set text color to Black
              } else {
                pdf.setTextColor(txtColor);  // White color
              }

              if (image.quantity || image.price || image.comment || image.fileName) {
                pdf.setFontSize(10);
                // Set text color
                const marginBottom = 1; // Adjust the bottom margin as needed
                let xElementDetails = xPos1 + 20;

                // Set line color for the box
                pdf.setDrawColor(0, 0, 0); // Black
                // Quantity
                if (image.quantity) {
                  pdf.text(`${image.unit?.toUpperCase() || "Container"}: ${image.quantity}`, xElementDetails, pageHeight - margin - marginBottom - 10);
                }
                // Price
                if (image.price) {
                  pdf.text(`Price: ${image.price}`, xElementDetails + 20, pageHeight - margin - marginBottom - 10);
                }
                // Comment
                if (image.comment) {
                  const commentX = xElementDetails + 45;
                  const commentY = pageHeight - margin - marginBottom - 10;
                  const commentWidth = 70; // Adjust the width as needed
                  const commentLines = pdf.splitTextToSize(`Comment: ${image.comment}`, commentWidth);

                  pdf.text(commentLines, commentX, commentY);
                }
                if (image.fileName) {
                  const linkText = `Open File: ${image.fileName}`;
                  const linkX = xElementDetails + 45; // X-coordinate of the link
                  const linkY = pageHeight - margin - marginBottom - 10; // Y-coordinate of the link
                  const linkWidth = pdf.getStringUnitWidth(linkText) * pdf.internal.getFontSize(); // Width of the link
                  const linkHeight = pdf.internal.getLineHeight(); // Height of the link

                  // Add the link
                  const fileUrl = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_UPLOAD_BASE_URL}/${image.fileName}`;
                  const link = { url: fileUrl, text: linkText, target: '_blank' };
                  pdf.link(linkX, linkY, linkWidth, linkHeight, link);

                  // Add the clickable text
                  const clickText = 'Click here to open the attachment';
                  pdf.textWithLink(clickText, linkX + 81, linkY, { url: fileUrl, target: '_blank' });
                }
              }

            } else { // IF IMAGE HEIGHT-width SAME 
              // Add text to the top left corner
              // Calculate page and row

              // Load the company logo
              const companyLogoWidth = 150 * pageWidth / 793;
              const companyLogoHeight = 60 * pageWidth / 793;
              const companyLogoXPos = pageWidth - companyLogoWidth - margin; // Adjust the position as needed
              const companyLogoYPos = margin; // Adjust the position as needed
              // const companyLogoData = `../../../../logo/Italica_logo2-removebg-preview.png`; // Replace with the actual path or URL of your company logo
              // pdf.addImage(companyLogoData, 'PNG', companyLogoXPos, companyLogoYPos, companyLogoWidth, companyLogoHeight);

              if (j === 0) {
                pdf.setFillColor(bgColor); // White color
                pdf.rect(0, 0, pageWidth, pageHeight, 'F'); // Fill rectangle with background color
                const companyLogoData = `../../../../logo/Italica_Logo_W.png`; // Load while logo
                pdf.addImage(companyLogoData, 'PNG', companyLogoXPos + 3, companyLogoYPos + 3, companyLogoWidth - 4, companyLogoHeight - 4);
              }

              const pageIndex = Math.floor(j / imagesPerPage);
              const rowIndex = j % imagesPerPage;
              const textXPos = margin + 5;
              const textYPos = margin + 2 + j * 210 + pageIndex * pageHeight;;
              const textFontSize = 14;
              pdf.setDrawColor(txtColor); // White
              pdf.setTextColor(txtColor); // White

              pdf.setLineWidth(1);
              pdf.setFontSize(textFontSize);
              if (image.design_name === image.buyer_design_name) {
                pdf.text(image.design_name, textXPos + 10, textYPos + 5);
              } else {
                const buyerDesignName = image.buyer_design_name ? ` | ${image.buyer_design_name}` : '';
                pdf.text(`${image.design_name}${buyerDesignName}`, textXPos + 10, textYPos + 5);
              }

              const tmpFinish = image.finish_id && image.finish_id.map(x => x.finish_name);
              const tmpStr = '# ' + tmpFinish.join(', '); // Join the finish names with commas
              pdf.setFontSize(10);
              pdf.text(tmpStr, textXPos + 10, textYPos + 9.5);

              pdf.setLineWidth(0.5);
              pdf.line(textXPos, textYPos + 18, textXPos + 8, textYPos + 18);
              pdf.line(textXPos, textYPos, textXPos, textYPos + 18);
              pdf.line(textXPos, textYPos, textXPos + 8, textYPos);
              pdf.setLineDash([9.5, 7], 0);
              pdf.line(textXPos + 8, textYPos, textXPos + 8, textYPos + 18);
              pdf.setFontSize(12);
              const thick = image.thickness_mm ? ' | THIK: ' + image.thickness_mm + 'mm' : '';
              pdf.text(convertToMMFormat(image.height_width) + thick, textXPos + 5, textYPos + 14.5);

              // Check if the page is not the first one, then add a new page
              if (j > 0 && rowIndex === 0) {
                pdf.addPage();
                pdf.setFillColor(bgColor); // White color
                pdf.rect(0, 0, pageWidth, pageHeight, 'F'); // Fill rectangle with background color
                pdf.setTextColor(txtColor);  // White color

                // Add text to the top left corner
                const textXPos = margin + 5;
                const textYPos = margin + 2;
                const textFontSize = 14;
                pdf.setLineWidth(1);

                pdf.setFontSize(textFontSize);
                if (image.design_name === image.buyer_design_name) {
                  pdf.text(image.design_name, textXPos + 10, textYPos + 5);
                } else {
                  const buyerDesignName = image.buyer_design_name ? ` | ${image.buyer_design_name}` : '';
                  pdf.text(`${image.design_name}${buyerDesignName}`, textXPos + 10, textYPos + 5);
                }

                const tmpFinish = image.finish_id && image.finish_id.map(x => x.finish_name);
                const tmpStr = '# ' + tmpFinish.join(', '); // Join the finish names with commas
                pdf.setFontSize(10);
                pdf.text(tmpStr, textXPos + 10, textYPos + 9.5);

                pdf.setLineWidth(0.5);
                pdf.line(textXPos, textYPos + 18, textXPos + 8, textYPos + 18);
                pdf.line(textXPos, textYPos, textXPos, textYPos + 18);
                pdf.line(textXPos, textYPos, textXPos + 8, textYPos);
                pdf.setLineDash([9.5, 7], 0);
                pdf.line(textXPos + 8, textYPos, textXPos + 8, textYPos + 18);
                pdf.setFontSize(12);
                const thick = image.thickness_mm ? ' | THIK: ' + image.thickness_mm + 'mm' : '';
                pdf.text(convertToMMFormat(image.height_width) + thick, textXPos + 5, textYPos + 14.5);

                const arrayLengthXPos = 105;
                const arrayLengthYPos = 16;
                const arrayLength = image.design_images ? image.design_images.length : 0;

                // Draw a circle with background color
                pdf.setFillColor(0); // Set background color to black
                pdf.circle(105, 15, circleRadius, 'F'); // Draw filled circle
                // Text for array length
                pdf.setTextColor(txtColor);  // White color
                pdf.setFontSize(10); // Set the font size
                pdf.text(arrayLengthXPos, arrayLengthYPos, `${arrayLength}`, { align: 'center' });
                pdf.setFontSize(8.5); // Set the font size
                pdf.text(99, 23, 'RANDOM', { align: 'left' });

                if (!isBlackImage) {
                  pdf.setTextColor(0, 0, 0); // Set text color to Black
                } else {
                  pdf.setTextColor(txtColor);  // White color
                }
                if (image.quantity || image.price || image.comment || image.fileName) {
                  pdf.setFontSize(10);
                  // Set text color
                  const marginBottom = 1; // Adjust the bottom margin as needed
                  let xElementDetails = 80;
                  // Set line color for the box
                  pdf.setDrawColor(0, 0, 0); // Black
                  // Quantity
                  if (image.quantity) {
                    pdf.text(`${image.unit?.toUpperCase() || "Container"}: ${image.quantity}`, xElementDetails, pageHeight - margin - marginBottom - 10);
                  }
                  // Price
                  if (image.price) {
                    pdf.text(`Price: ${image.price}`, xElementDetails + 20, pageHeight - margin - marginBottom - 10);
                  }
                  // Comment
                  if (image.comment) {
                    const commentX = xElementDetails + 45;
                    const commentY = pageHeight - margin - marginBottom - 10;
                    const commentWidth = 70; // Adjust the width as needed
                    const commentLines = pdf.splitTextToSize(`Comment: ${image.comment}`, commentWidth);

                    pdf.text(commentLines, commentX, commentY);
                  }

                  if (image.fileName) {
                    const linkText = `Open File: ${image.fileName}`;
                    const linkX = xElementDetails + 45; // X-coordinate of the link
                    const linkY = pageHeight - margin - marginBottom - 10; // Y-coordinate of the link
                    const linkWidth = pdf.getStringUnitWidth(linkText) * pdf.internal.getFontSize(); // Width of the link
                    const linkHeight = pdf.internal.getLineHeight(); // Height of the link

                    // Add the link
                    const fileUrl = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_UPLOAD_BASE_URL}/${image.fileName}`;
                    const link = { url: fileUrl, text: linkText, target: '_blank' };
                    pdf.link(linkX, linkY, linkWidth, linkHeight, link);

                    // Add the clickable text
                    const clickText = 'Click here to open the attachment';
                    pdf.textWithLink(clickText, linkX + 81, linkY, { url: fileUrl, target: '_blank' });
                  }
                }

                // Load the company logo
                const companyLogoWidth = 150 * pageWidth / 793;
                const companyLogoHeight = 60 * pageWidth / 793;
                const companyLogoXPos = pageWidth - companyLogoWidth - margin; // Adjust the position as needed
                const companyLogoYPos = margin; // Adjust the position as needed
                // const companyLogoData = `../../../../logo/Italica_logo2-removebg-preview.png`; // Replace with the actual path or URL of your company logo
                // pdf.addImage(companyLogoData, 'PNG', companyLogoXPos, companyLogoYPos, companyLogoWidth, companyLogoHeight);
                const companyLogoData = `../../../../logo/Italica_Logo_W.png`; // Load while logo
                pdf.addImage(companyLogoData, 'PNG', companyLogoXPos + 3, companyLogoYPos + 3, companyLogoWidth - 4, companyLogoHeight - 4)
              }

              const arrayLengthXPos = 105;
              const arrayLengthYPos = 16;
              const arrayLength = image.design_images ? image.design_images.length : 0;

              // Draw a circle with background color
              pdf.setFillColor(0); // Set background color to black
              pdf.circle(105, 15, circleRadius, 'F'); // Draw filled circle
              // Text for array length
              pdf.setTextColor(txtColor); // Set text color to white
              pdf.setFontSize(10); // Set the font size
              pdf.text(arrayLengthXPos, arrayLengthYPos, `${arrayLength}`, { align: 'center' });
              pdf.setFontSize(8.5); // Set the font size
              pdf.text(99, 23, 'RANDOM', { align: 'left' });
              pdf.setFillColor(bgColor); // Set background color
              if (!isBlackImage) {
                pdf.setTextColor(0, 0, 0); // Set text color to Black
              } else {
                pdf.setTextColor(txtColor);  // White color
              }
              if (image.quantity || image.price || image.comment || image.fileName) {
                pdf.setFontSize(10);
                // Set text color
                const marginBottom = 1; // Adjust the bottom margin as needed
                let xElementDetails = 80;
                // Set line color for the box
                pdf.setDrawColor(0, 0, 0); // Black
                // Quantity
                if (image.quantity) {
                  pdf.text(`${image.unit?.toUpperCase() || "Container"}: ${image.quantity}`, xElementDetails, pageHeight - margin - marginBottom - 10);
                }
                // Price
                if (image.price) {
                  pdf.text(`Price: ${image.price}`, xElementDetails + 20, pageHeight - margin - marginBottom - 10);
                }
                // Comment
                if (image.comment) {
                  const commentX = xElementDetails + 45;
                  const commentY = pageHeight - margin - marginBottom - 10;
                  const commentWidth = 70; // Adjust the width as needed
                  const commentLines = pdf.splitTextToSize(`Comment: ${image.comment}`, commentWidth);

                  pdf.text(commentLines, commentX, commentY);
                }

                if (image.fileName) {
                  const linkText = `Open File: ${image.fileName}`;
                  const linkX = xElementDetails + 45; // X-coordinate of the link
                  const linkY = pageHeight - margin - marginBottom - 10; // Y-coordinate of the link
                  const linkWidth = pdf.getStringUnitWidth(linkText) * pdf.internal.getFontSize(); // Width of the link
                  const linkHeight = pdf.internal.getLineHeight(); // Height of the link

                  // Add the link
                  const fileUrl = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_UPLOAD_BASE_URL}/${image.fileName}`;
                  const link = { url: fileUrl, text: linkText, target: '_blank' };
                  pdf.link(linkX, linkY, linkWidth, linkHeight, link);

                  // Add the clickable text
                  const clickText = 'Click here to open the attachment';
                  pdf.textWithLink(clickText, linkX + 81, linkY, { url: fileUrl, target: '_blank' });
                }
              }

              let x = margin;
              // let y = margin + 24;
              let y = margin + 6;

              // Display six small images in a row
              let topGalleryImagesH = 0;
              if (image.design_images.length !== 3) {
                for (let i = 0; i < Math.min(imagesPerRow, image.design_images.length); i++) {
                  const designImage = image.design_images[i];
                  let size = image.height_width;
                  size = size.split(' X ');
                  if (designImage && designImage.filePath) {
                    if (size[0] === size[1]) {
                      additionalHeight = 0;
                      topGalleryImagesH = 22;
                    } else if (Number(size[0]) < Number(size[1])) {
                      additionalHeight = 10;
                      topGalleryImagesH = 22;
                    } else if (Number(size[0]) > Number(size[1])) {
                      additionalHeight = -8;
                      topGalleryImagesH = 8;
                    } else {
                      additionalHeight = 0;
                      topGalleryImagesH = 0;
                    }
                  }
                }
              } else {
                additionalHeight = 0;
              }

              // Display the barcode next to the row of small images
              const barcodeXPos = x + (imagesPerRow + 1) * (smallImgWidth + margin);
              const barcodeYPos = y;
              const qrCodeSize = 20; // Adjust the size as needed
              if (image.img_url_for_3d_view) {
                const qr = QRCode(0, 'L');
                qr.addData(image.img_url_for_3d_view);
                qr.make();
                const qrData = qr.createDataURL(4, 0);

                // Add a hyperlink annotation to the QR code
                const link = { url: image.img_url_for_3d_view, target: '_blank' }; // Adjust target as needed
                pdf.link(barcodeXPos, barcodeYPos, qrCodeSize, qrCodeSize, link);
                pdf.addImage(qrData, 'JPEG', barcodeXPos, barcodeYPos, qrCodeSize, qrCodeSize);

                // Display a static image above the barcode
                const staticImgWidth = 20; // Width of the static image in mm
                const staticImgHeight = 20; // Height of the static image in mm
                const staticImgXPos = 155.5; // Adjust the position as needed
                const staticImgYPos = barcodeYPos - staticImgHeight + 5; // Adjust the position as needed
                const staticImgData = '`../../../../logo/360_image.png'; // Replace with the actual path or URL of your static image
                // Add a hyperlink annotation to the static image
                const staticImgLink = { url: image.img_url_for_3d_view, target: '_blank' }; // Replace with the actual URL of your static image
                pdf.link(staticImgXPos, staticImgYPos, staticImgWidth, staticImgHeight, staticImgLink);
                pdf.addImage(staticImgData, 'PNG', staticImgXPos, staticImgYPos, staticImgWidth, staticImgHeight);
              }

              let loopC = 1, addH = 0, on2Img = 0;;
              if (image.design_images.length <= 3) {
                loopC = 2;
                on2Img = 4;
              } else {
                addH = 4;
              }
              let yPoss = 0;
              y += smallImgHeight + margin;
              for (let i = 0; i < loopC; i++) {
                const xPos = x;
                let yPos = y + i * (columnImgHeight + margin) + additionalHeight + (i * on2Img);
                let gapBetweenImages = 0;
                const designImage = image.design_images[i + 1]; // Adjust index to get the correct images
                if (designImage && designImage.filePath) {
                  const imgWidth = columnImgWidth; // Adjust width as needed
                  const imgHeight = columnImgHeight + addH + on2Img; // Adjust height as needed
                  yPos += i * gapBetweenImages;
                  const imgData = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${designImage.filePath}`;
                  pdf.addImage(imgData, 'JPEG', xPos, yPos, imgWidth, imgHeight);
                  yPoss = yPos + i * gapBetweenImages + imgHeight + margin;
                }
              }

              const largeImgXPos = x + columnImgWidth + margin;
              let yAdd = 0;
              if (image.design_images.length > 3) {
                let xPos = x;
                for (let i = 0; i < Math.min(imagesPerRow, image.design_images.length); i++) {
                  let yPos = yPoss;
                  const designImage = image.design_images[3 + i];
                  let size = image.height_width;
                  size = size.split(' X ');
                  let newRow = 0;
                  if (i !== 0 && newRow === 0) {
                    xPos += (smallImgWidth + margin + 0.3);
                  }
                  if ((xPos) > 66) {
                    yAdd += 20 + margin;
                    xPos = x;
                    newRow = 1;
                  }

                  if (designImage && designImage.filePath) {
                    const imgData = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${designImage.filePath}`;
                    pdf.addImage(imgData, 'JPEG', xPos, yPos + yAdd, 20, 20);
                  }
                }
              }

              // Calculate y position for the next row after adding the column of small images
              const columnHeight = 2 * columnImgHeight + margin;
              // Display one large image to the side of the column
              const largeImgYPos = y + additionalHeight;
              const largeDesignImage = image.design_images[0]; // Adjust index
              if (largeDesignImage && largeDesignImage.filePath) {
                let size = image.height_width;
                size = size.split(' X ');
                let calculatedLargeImgWidth = largeImgWidth;
                let calculatedLargeImgHeight = largeImgHeight;

                // Apply size condition
                if (size[0] === size[1]) {
                  calculatedLargeImgWidth = largeImgWidth; // Adjust width as needed
                  calculatedLargeImgHeight = largeImgHeight; // Adjust height as needed
                  additionalHeight = 0
                } else if (Number(size[0]) < Number(size[1])) {
                  calculatedLargeImgWidth = largeImgWidth;
                  calculatedLargeImgHeight = 152;
                  additionalHeight = 50
                } else if (Number(size[0]) > Number(size[1])) {
                  calculatedLargeImgWidth = largeImgWidth;
                  calculatedLargeImgHeight = 97;
                  additionalHeight = -41
                } else {
                  calculatedLargeImgWidth = largeImgWidth;
                  calculatedLargeImgHeight = largeImgHeight;
                }
                const largeImgData = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${largeDesignImage.filePath}`;
                pdf.addImage(largeImgData, 'JPEG', largeImgXPos, largeImgYPos, calculatedLargeImgWidth, calculatedLargeImgHeight);
              }

              // Calculate y position for the next row after adding the large image
              y += columnHeight + margin;

              if (!isBlackImage) {
                pdf.setTextColor(0, 0, 0); // Set text color to Black
              } else {
                pdf.setTextColor(txtColor);  // White color
              }
              if (image.quantity || image.price || image.comment || image.fileName) {
                pdf.setFontSize(10);
                // Set text color
                const marginBottom = 1; // Adjust the bottom margin as needed
                let xElementDetails = 80;
                // Set line color for the box
                pdf.setDrawColor(0, 0, 0); // Black
                // Quantity
                if (image.quantity) {
                  pdf.text(`${image.unit?.toUpperCase() || "Container"}: ${image.quantity}`, xElementDetails, pageHeight - margin - marginBottom - 10);
                }
                // Price
                if (image.price) {
                  pdf.text(`Price: ${image.price}`, xElementDetails + 20, pageHeight - margin - marginBottom - 10);
                }
                // Comment
                if (image.comment) {
                  const commentX = xElementDetails + 45;
                  const commentY = pageHeight - margin - marginBottom - 10;
                  const commentWidth = 70; // Adjust the width as needed
                  const commentLines = pdf.splitTextToSize(`Comment: ${image.comment}`, commentWidth);

                  pdf.text(commentLines, commentX, commentY);
                }

                if (image.fileName) {
                  const linkText = `Open File: ${image.fileName}`;
                  const linkX = xElementDetails + 45; // X-coordinate of the link
                  const linkY = pageHeight - margin - marginBottom - 10; // Y-coordinate of the link
                  const linkWidth = pdf.getStringUnitWidth(linkText) * pdf.internal.getFontSize(); // Width of the link
                  const linkHeight = pdf.internal.getLineHeight(); // Height of the link

                  // Add the link
                  const fileUrl = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_UPLOAD_BASE_URL}/${image.fileName}`;
                  const link = { url: fileUrl, text: linkText, target: '_blank' };
                  pdf.link(linkX, linkY, linkWidth, linkHeight, link);

                  // Add the clickable text
                  const clickText = 'Click here to open the attachment';
                  pdf.textWithLink(clickText, linkX + 81, linkY, { url: fileUrl, target: '_blank' });
                }
              }
            }

            // Add a new page for 3D Image
            pdf.addPage();
            pdf.setFillColor(bgColor); // White color
            pdf.rect(0, 0, pageWidth, pageHeight, 'F'); // Fill rectangle with background color
            const imageWidth = pageWidth - 12; // Fixed width for each image //  pageWidth - 4
            const imageHeight = pageHeight - 12; // Fixed height for each image
            let xPos = 6; // margin
            let yPos = 6;
            const attachmentImgData = image.design_image && image.design_image !== '' ?
              `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}/${image.design_image}` :
              `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${image.design_images[0].filePath}`;
            pdf.addImage(attachmentImgData, 'JPEG', xPos, yPos, imageWidth, imageHeight);
          }

          // Check if there is attachment data
          if (attachmentData && attachmentData.length > 0) {
            const imagesPerRow = 3; // Number of images to display in a row
            const imagesPerPage = 12; // Number of images to display per page

            for (let page = 0; page < Math.ceil(attachmentData.length / imagesPerPage); page++) {
              // Add a new page for attachment data
              pdf.addPage();
              pdf.setFillColor(bgColor); // White color
              pdf.rect(0, 0, pageWidth, pageHeight, 'F'); // Fill rectangle with background color
              pdf.setTextColor(txtColor); // Set text color to white

              // Add title for attachment data
              const imageWidth = 60; // Fixed width for each image
              const imageHeight = 40; // Fixed height for each image
              const rowHeight = 60; // Height of each row
              const textWidth = 65;
              const gapWidth = 12; // Width of the gap between images
              // Display attachment data on the current page
              let xPos = margin;
              let yPos = margin + 20;

              for (let i = page * imagesPerPage; i < Math.min((page + 1) * imagesPerPage, attachmentData.length); i++) {
                const attachmentImage = attachmentData[i];
                // Wrap the message text to fit within the specified width
                const wrappedText = pdf.splitTextToSize(attachmentImage.message, textWidth);

                // Add wrapped message text
                pdf.setFontSize(12);
                pdf.text(wrappedText, xPos, yPos);

                // Add image
                const attachmentImgData = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_UPLOAD_BASE_URL}/attachment/${attachmentImage.image_name}`;
                pdf.addImage(attachmentImgData, 'JPEG', xPos, yPos + 10, imageWidth, imageHeight);

                // Move to the next column with gap
                xPos += imageWidth + gapWidth;

                // Check if a new row needs to be started
                if (i % imagesPerRow === imagesPerRow - 1) {
                  xPos = margin;
                  yPos += rowHeight;
                }
              }
            }
          }

          // Save or download the PDF
          pdf.save(`italica-catelogue.pdf`);
          setImageLoading(false);
        }
      }, 500);
    } catch (error) {
      console.error('Error generating PDF:', error);
      setImageLoading(false); // Hide loader in case of an error
    }
  };


  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  const handleLoginEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmailInput(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  // let currentIndex = 0;
  //Update Image in Modal

  function preloadImage(index) {
    const nextImage = sliderImages[index];
    if (nextImage && nextImage.filePath) {
      const img = new window.Image();
      img.src = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/` + nextImage.filePath;
    }
  }

  function updateModalContent(isNext) {
    const modalContainer = document.querySelector(".modal-image-container");
    const modalImg = document.querySelector(".modal-img");
    const currentImage = sliderImages[currentIndex];

    if (currentImage && currentImage.filePath) {
      modalContainer.style.overflow = "hidden"; // Hide content outside modal boundaries
      modalImg.style.transition = "transform 0.5s ease-in-out, opacity 0.5s ease-in-out";
      modalImg.style.opacity = 1;

      // Add the appropriate class based on the isNext parameter
      const animationClass = !isNext ? "slide-right" : "slide-left";
      modalImg.className = `modal-img ${animationClass}`;

      setTimeout(() => {
        modalImg.src = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/` + currentImage.filePath;
        void modalImg.offsetWidth;
        modalImg.style.opacity = 1;

        // Remove the animation class after the animation
        setTimeout(() => {
          modalImg.className = "modal-img";
          modalContainer.style.overflow = "visible"; // Show content outside modal boundaries
        }, 700); // Adjust the delay based on your needs
      }, 0); // Adjust the delay based on your needs
    }
  }

  const nextButton = () => {
    const newIndex = currentIndex + 1 >= sliderImages.length ? 0 : currentIndex + 1;
    preloadImage(newIndex); // Preload the next image
    setCurrentIndex(newIndex);
    updateModalContent(true);
  };

  const prevButton = () => {
    const newIndex = currentIndex - 1 < 0 ? sliderImages.length - 1 : currentIndex - 1;
    preloadImage(newIndex); // Preload the previous image
    setCurrentIndex(newIndex);
    updateModalContent(false);
  };


  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveClick = async (index, id) => {
    try {
      // Save the edited message to the attachmentData
      const updatedAttachmentData = [...attachmentData];
      updatedAttachmentData[index].message = editedMessage;

      // Send the updated attachmentData to the server
      const response = await axios.put(
        `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/design-attach/update-attachment/${id}`,
        { message: editedMessage }
      );

      // Check if the update was successful
      if (response.data && response.data.status === 200) {
        toast.success('Record updated successfully')
        setAttachmentData(updatedAttachmentData);
        setEditingIndex(null);
      } else {
        console.error('Failed to update record:', response.data);
        // Handle the error case
      }
    } catch (error) {
      console.error('Error while updating record:', error);
      // Handle the error case
    }
  };

  const handleDeleteConfirmation = (attachmentId) => {
    setDeleteAttachmentId(attachmentId);
    setDeleteConfirmationOpen(true);
  };

  const handleEditMessage = (index) => {
    setEditingIndex(index);
    setEditedMessage(attachmentData[index].message);
  };

  const handleDeleteImage = async () => {
    try {
      // Make API request to delete the attachment
      const response = await axios.delete(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/design-attach/delete-attachment/${deleteAttachmentId}`);
      if (response.data.status === 200) {
        toast.success('Attachment deleted successfully.');
        setDeleteConfirmationOpen(false)
        setAttachmentData((prevData) => prevData.filter((attachment) => attachment._id !== deleteAttachmentId));
      } else {
        console.error('Failed to delete attachment:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting attachment:', error);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const modal = document.querySelector('.modal.gallery-modal');
      const modalContent = document.querySelector('.modal.gallery-modal .modal-content');
      const closeIcon = document.querySelector('.modal.gallery-modal .close-icon');
      const modalNav = document.querySelector('.modal.gallery-modal .modal-nav');

      // Check if the clicked element is outside the modal and not inside specific elements
      if (
        modal &&
        modal.contains(event.target) &&
        !modalContent.contains(event.target) &&
        !closeIcon.contains(event.target) &&
        !modalNav.contains(event.target)
      ) {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isModalOpen]);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      // If "Select All" is checked, select all images
      setSelectAllDesignId(selectedImages.map(image => image.des_id));
    } else {
      // If "Select All" is unchecked, clear selected images
      setSelectAllDesignId([]);
    }
  };

  const handleDownload = async (design_name) => {
    try {
      for (let i = 0; i < sliderImages.length; i++) {
        const x = sliderImages[i];
        const imageUrl = `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${x.filePath}`;
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        await saveAs(blob, `${design_name + '-' + (i + 1)}.jpg`);
      }

    } catch (error) {
      console.error('Error creating zip file:', error);
    }
  };

  useEffect(() => {
    // Retrieve email from localStorage and set it as the initial value
    const storedEmail = localStorage.getItem('userEmail');
    const storedAdmin = localStorage.getItem('isAdmin');
    setIsAdmin(storedAdmin)
    if (storedEmail) {
      setEmail(storedEmail);
      setUserName(storedEmail);
    }
  }, [showFormPopup, showSelected, otpVerified]);

  const logout = () => {
    setShowOtpPopup(true)
    setOtpVerified(false);
    localStorage.removeItem('otpSubmissionTime');
    localStorage.removeItem('verifiedSlug');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('selectedOption');
    localStorage.removeItem('isAdmin');
    setIsLogoutModalOpen(false)
    setSelectedImages([])
  }

  const selectedType = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    localStorage.setItem('selectedOption', selectedValue);
  }

  // useEffect to update the selectedOption from localStorage on component mount
  useEffect(() => {
    const storedOption = localStorage.getItem('selectedOption');
    if (storedOption) {
      setSelectedOption(storedOption);
    }
  }, []);

  const handleAddRepeaterItem = () => {
    if (repeaterItems.length < 10) {
      setRepeaterItems((prevItems) => [
        ...prevItems,
        { id: prevItems.length + 1, file: null, text: '' },
      ]);
    } else {
      // Optionally, you can provide feedback to the user that the limit has been reached.
      // For example, you can show a toast message.
      toast.error('Maximum limit of 10 repeater items reached.')
    }
  };

  const handleRemoveRepeaterItem = (id) => {
    setRepeaterItems((prevItems) => prevItems.filter(item => item.id !== id));
  };

  const handleFileChange = (id, event) => {
    const newItems = repeaterItems.map((item) => {
      if (item.id === id) {
        const file = event.target.files[0];
        if (file) {
          // Check if the selected file is an image
          if (/^image\/(jpeg|png|jpg)$/.test(file.type)) {
            const fileName = file.name;

            // Check if the file name already exists in other items
            const duplicateFileName = repeaterItems
              .filter((otherItem) => otherItem.id !== id)
              .some((otherItem) => otherItem.file && otherItem.file.name === fileName);

            if (duplicateFileName) {
              toast.error('Duplicate image file. Please select a different file.');
              return item; // Keep the previous item state
            } else {
              return { ...item, file: file || null };
            }
          } else {
            // Show an error or alert for non-image files
            toast.error('Please select an image file.');
            return item; // Keep the previous item state
          }
        } else {
          // No file selected, reset the file property to null
          return { ...item, file: null };
        }
      }
      return item;
    });
    setRepeaterItems(newItems);
  };

  const handleTextChange = (id, event) => {
    const newItems = repeaterItems.map((item) => {
      if (item.id === id) {
        return { ...item, text: event.target.value };
      }
      return item;
    });
    setRepeaterItems(newItems);
  };

  const handleRepeaterSubmit = async () => {
    try {
      const storedEmail = localStorage.getItem('userEmail');
      const isFormValid = repeaterItems.every(item => item.text.trim() !== '' && item.file !== null);

      if (isFormValid) {
        // Assuming you have an API endpoint to submit data
        const formData = new FormData();

        repeaterItems.forEach((item, index) => {
          if (item.file) {
            formData.append(`file`, item.file || '');
          }
          formData.append('text', item.text || '');
          formData.append('id', item.id || '');
        });

        const response = await axios.post(
          `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/design-attach/add-attachment/${slug}/${storedEmail}`,
          formData
        );

        if (response.data && response.data.status === 200) {
          // Handle success, clear the repeater items or perform other actions
          setRepeaterItems([{ id: 1, file: null, text: '' }]);
          toast.success('Data submitted successfully!')
          getAllAttachment()
        } else {
          // Handle errors
          console.error('Failed to submit data');
          toast.error("Failed to submit data")
        }
      } else {
        toast.error("Form is not valid")
        // Handle invalid form (show error messages, etc.)
      }
    } catch (error) {
      console.error('Error while submitting data:', error);
    }
  };

  const getSuggestions = (inputValue) => {
    return axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_API_BASE_URL}/design/get-product-design-list?query=${inputValue}`)
      .then((data) => data.data || [])
      .catch((error) => {
        console.error('Error fetching suggestions:', error);
        return [];
      });
  };

  const handleInputChange = (event, { newValue }) => {
    setSearchQuery(newValue);
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(async () => {
      try {
        const suggestionsResult = await getSuggestions(value);
        // const uniqueDesignNames = [...new Set(suggestionsResult && suggestionsResult.designs
        //   .map(item => item.design_name))];
        const uniqueDesignNames = [...new Set(suggestionsResult.designs)];
        setSuggestedValues(uniqueDesignNames);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        // Handle errors here
        setSuggestedValues([]);
      }
    }, 500);
  };

  const getSuggestionValue = (suggestion) => {
    return suggestion;
  };

  // Render function for autosuggestion
  const renderSuggestion = suggestion => {
    return (
      <>
        <div className="suggestion-item">
          {suggestion}
        </div>
      </>
    )
  };

  return (
    <>
      {showOtpPopup && (
        <div className="popup-container">
          <div className="popup-content">
            <h2>Login</h2>
            {verificationStatus.type === 'success' && (
              <p className="success-message" style={{ color: 'green' }}>
                {verificationStatus.message}
              </p>
            )}
            {verificationStatus.type === 'error' && (
              <p className="error-message" style={{ color: 'red' }}>
                {verificationStatus.message}
              </p>
            )}
            <form onSubmit={handleOtpSubmit}>
              <TextField
                type="email"
                label="Enter Email"
                variant="outlined"
                fullWidth
                margin="normal"
                autoComplete="off"
                value={emailInput}
                onChange={handleLoginEmailChange}
                error={!isEmailValid}
                helperText={!isEmailValid ? 'Invalid email format' : ''}
                required
              />
              <TextField
                type={showPassword ? 'text' : 'password'}
                label="Enter OTP"
                variant="outlined"
                fullWidth
                margin="normal"
                autoComplete="off"
                value={otpInput}
                onChange={(e) => setOtpInput(e.target.value)}
                onWheel={(e) => e.preventDefault()}
                inputProps={{
                  min: 0,
                  inputMode: 'numeric',
                  pattern: '[0-9]*'
                }}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleTogglePasswordVisibility}
                      >
                        {showPassword ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button type="submit" variant="contained" color="primary" style={{ marginTop: '10px', textTransform: 'none' }}>
                Verify OTP
              </Button>
            </form>
            {/* Optionally, add a close button for the OTP popup */}
            {/* <i className="fas fa-times form-close-drawer-icon" onClick={() => setShowOtpPopup(false)}></i> */}
          </div>
        </div>
      )}

      {/* Render the design listing only when OTP is verified */}
      {otpVerified && (
        <>
          <nav className="navbar">
            <div className='container-fluid'>
              <div className="logo">
                <img src={`../../../../logo/Italica_logo2-removebg-preview.png`} alt="Logo" width={150} height={62} />
              </div>
              <div className="nav-right-content">
                <div className="search-bar">
                  {/* <input
                    type="text"
                    placeholder="Search images by name"
                    value={searchQuery}
                    onChange={handleInputChange}
                  /> */}
                  <Autosuggest
                    suggestions={suggestedValues}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={() => setSuggestedValues([])}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={{
                      placeholder: "Search images by name",
                      value: searchQuery,
                      onChange: handleInputChange,
                    }}
                  />
                  {searchQuery && (
                    <RxCross2 onClick={handleResetSearch} className="reset-icon" />
                  )}
                </div>
                {/* <button onClick={() => setShowSelected(true)} className="show-selected-button">Show Selected Images</button> */}
                <div className="user-name">
                  {userName}
                </div>
                <div className="show-selected-button" onClick={() => setShowSelected(true)}>
                  <i className="fa-regular fa-heart" title='Selected images'></i>
                  {selectedImages && selectedImages.length > 0 && (
                    <span className="selected-image-count">{selectedImages && selectedImages.length}</span>
                  )}
                </div>
                <div className="show-selected-button" onClick={() => setIsLogoutModalOpen(true)} >
                  <BiLogOut title='Logout' />
                </div>
              </div>
            </div>
            <Dialog open={isLogoutModalOpen} onClose={handleCancelLogout}>
              <DialogTitle>Confirmation</DialogTitle>
              <DialogContent>
                Are you sure you want to logout?
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={() => setIsLogoutModalOpen(false)}>
                  Cancel
                </Button>
                <Button color="primary" onClick={logout}>
                  Logout
                </Button>
              </DialogActions>
            </Dialog>
          </nav>
          <div className="attractive-text">
            <div className='container'>
              <h1>Welcome to Italica Image Gallery</h1>
              <p>Explore and enjoy a wide collection of beautiful images.</p>
            </div>
          </div>
          <div className='filter-wrapper'>
            <div className="container">
              <div className="filter-row">
                <div className="filter-select-list">
                  <FormControl className="filter custom-form-control" x={{ m: 1, minWidth: 120 }} size="small">
                    {/* <InputLabel id="select-size-label" style={{ fontSize: '14px' }}>Select Finish</InputLabel> */}
                    <Autocomplete
                      multiple
                      id="demo-multiple-checkbox"
                      options={finishData}
                      limitTags={1}
                      disableCloseOnSelect
                      value={selectedName.map((id) => finishData.find((item) => item._id === id))}
                      onChange={(event, newValue) => selectFinish(event, newValue)}
                      getOptionLabel={(option) => option.finish_name || ''}
                      isOptionEqualToValue={(option, value) => option?._id === value?._id}
                      renderOption={(props, option, { selected }) => (
                        <li {...props} style={optionStyle}>
                          <Checkbox checked={selected} />
                          <ListItemText primary={option.finish_name} />
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Finish"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl className="filter custom-form-control" x={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="select-size-label" style={{ fontSize: '16px' }}>Select Size</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={selectedSize}
                      label="Select Size"
                      onChange={selectFinishSize}
                      renderValue={(selected) => (
                        <div style={{ fontSize: '14px' /* set your desired font size here */ }}>
                          {selected.map((id) => {
                            const [width, height] = id.split('X');
                            return `${width} x ${height}`;
                          })}
                        </div>
                      )}
                    >
                      {sizeByfinishData.map((size, index) => (
                        <MenuItem key={index} value={size}>
                          <Checkbox checked={selectedSize.includes(size)} />
                          <ListItemText primary={size} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className="filter custom-form-control" sx={{ m: 1, minWidth: 120 }} size="small">
                    {/* <InputLabel id="select-category-label" style={{ fontSize: '14px' }}>Select Category</InputLabel> */}
                    <Autocomplete
                      multiple
                      id="select-category"
                      limitTags={1}
                      options={updateCategoryListData.filter((category) => category !== null && category !== undefined)}
                      value={selectedCategory}
                      onChange={(event, newValue) => setSelectedCategory(newValue)}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox checked={selected} />
                          <ListItemText primary={option} />
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Category"
                          variant="outlined"
                          size="small"
                        // InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl className="filter custom-form-control" x={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="select-color-label" style={{ fontSize: '16px' }}>Select Color</InputLabel>
                    <Select
                      labelId="select-color-label"
                      id="select-color"
                      value={selectedColor}
                      label="Select Color"
                      onChange={(e) => setSelectedColor(e.target.value)}
                      renderValue={(selected) => (
                        <div style={{ fontSize: '14px' /* set your desired font size here */ }}>
                          {selected}
                        </div>
                      )}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      {colors.map((color, index) => (
                        <MenuItem key={index} value={color.label}>
                          {color.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className="filter custom-form-control" x={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="select-design-type-label" style={{ fontSize: '16px' }}>Select Design Type</InputLabel>
                    <Select
                      labelId="select-design-type-label"
                      id="select-design-type"
                      value={selectedDesignType}
                      label="Select Design Type"
                      onChange={(e) => setSelectedDesignType(e.target.value)}
                      renderValue={(selected) => (
                        <div style={{ fontSize: '14px' /* set your desired font size here */ }}>
                          {selected}
                        </div>
                      )}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      {designType.map((type, index) => (
                        <MenuItem key={index} value={type.label}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button onClick={handleResetFilters} style={{ display: 'flex', alignItems: 'center', fontSize: '30px', color: '#112d4a' }}>
                    &#8635;
                  </Button>
                  <Button onClick={() => setSortBy('a-z')} style={{ display: 'flex', padding: '3px', alignItems: 'center', fontSize: '20px', color: '#112d4a', backgroundColor: '#f0f0f0' }}>
                    A-Z
                  </Button>
                  <div className="attractive-total">
                    Total: {totalImages}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='image-container container'>

            {
              !apiCallCompleted && (
                <Loader />
              )}
            {apiCallCompleted && images && images.length > 0 ? (
              <div ref={imagesContainerRef}>
                {imageLoading && (
                  <div className="loader-container">
                    <div className="main_loader"></div>
                  </div>
                )}
                <Masonry
                  breakpointCols={{ default: 6, 1440: 5, 1200: 4, 1100: 3, 700: 2, 500: 1 }}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {images
                    .filter((image) => isValidImage(image))
                    .map((image, index) => (
                      <>
                        <div
                          key={index}
                          className={`masonry-item ${selectedImages && selectedImages.includes(image) ? 'selected' : ''}`}
                        >
                          {/* <div className="image-overlay">
                      <p className="image-random">{image.no_of_randome}</p>
                    </div> */}
                          <div className='masonry-image' onClick={() => handleImageClick(image)}>
                            <img
                              src={`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${image.design_file}`}
                              alt={image.design_name}

                              loading="lazy"
                              onError={(e) => {
                                handleImageError(image);
                                // e.target.src = "../../../../images/no-image-icon.png";
                              }}
                            />
                            {/* <div className='masonry-overlay'>
                              <i className="fa-solid fa-up-right-and-down-left-from-center"></i>
                            </div> */}
                          </div>
                          {image.design_type && image.design_type === "New" && (
                            <div className="design-type-container">
                              <p className="design-type">
                                {image.design_type}
                              </p>
                            </div>
                          )}
                          <div className='masonry-check'>
                            <input
                              type="checkbox"
                              checked={selectedImages && selectedImages.includes(image)}
                              onChange={() => handleImageSelect(image)}
                              id={`checkbox-${index}`}
                            />
                            <label htmlFor={`checkbox-${index}`} />
                          </div>
                          {/* <div className='masonry-check' style={{ right: '60px' }}>
                            <input
                              type="checkbox"
                              checked={compareImages && compareImages.includes(image)}
                              onChange={() => handleCompareImage(image)}
                              id={`checkbox-${index}`}
                            />
                            <label htmlFor={`checkbox-${index}`} />
                          </div> */}
                          <div className='masonry-text'>
                            <p className="image-name">
                              <SiTraefikproxy />
                              {image.design_name === image.buyer_design_name
                                ? image.design_name
                                : image.buyer_design_name
                                  ? ` ${image.buyer_design_name} | ${image.design_name}`
                                  : ` ${image.design_name}`}
                            </p>
                            <p className="image-no-of-randome" title='No of randome'>
                              <i className="fas fa-layer-group"></i>{image.no_of_randome}   {
                                image.img_url_for_3d_view && (
                                  <a href={image.img_url_for_3d_view} target="_blank" rel="noopener noreferrer">
                                    <div>
                                      <Tb360View style={{ fontSize: "30px", color: "#555" }} title='360 Link' />
                                      {/* <img src='../../../../logo/360_image.png' style={{ height: '35px', width: '71px', boxShadow: 'none' }} alt="" title='360 Link'></img> */}
                                    </div>
                                  </a>
                                )
                              } <div><IoMdEye onClick={() => handleEyeIconClick(image)} style={{ fontSize: '20px', cursor: 'pointer' }} title='All Randomes' /></div></p>
                            {image.height_width && (
                              <p className="image-size">
                                <SiZenn />  {convertToMMFormat(image.height_width)}
                                {/* {image.height_width.split('X')[0].trim()} x {image.height_width.split('X')[1].trim()} */}
                              </p>
                            )}
                            {image.design_category && (
                              <p className="design-category"><MdCategory /> {image.design_category}</p>
                            )}
                            {image.finish_array && image.finish_array.length > 0 && (
                              <p className="finish-name">
                                <GiFinishLine />  {image.finish_array.map((finish, finishIndex) => finish.finish_name).join(' | ')}
                              </p>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                </Masonry>
              </div>
            ) : apiCallCompleted && images && images.length === 0 ? (
              <div className="no-data-message">No data found.</div>
            ) : null}
          </div>
          {/* {totalImages > visibleImages && !loading && (
            <div className='load-more-button'>
              <button onClick={loadMoreImages}>Load More</button>
            </div>
          )} */}

          <Dialog open={showFaces} onClose={() => { setShowFaces(false); setShowDesignImages([]); }} PaperProps={{
            style: {
              borderRadius: '20px',
              boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#D4D4D4',
              padding: '20px'
            },
          }}>
            <DialogTitle>
              All Randomes
              <IconButton aria-label="close" onClick={() => { setShowFaces(false); setShowDesignImages([]); }} style={{ position: 'absolute', right: '10px', top: '10px' }}>
                <MdClose style={{ fontSize: '20px' }} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div className="design-image-grid">
                {showDesignImages && showDesignImages.map((attachment, index) => (
                  <img
                    key={index}
                    src={`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${attachment.filePath}`}
                    className="design-image-image"
                    style={{ width: '100%' }}
                    alt='Design'
                  />
                ))}
              </div>
            </DialogContent>
          </Dialog>
          <Dialog
            open={showComparision}
            onClose={() => handleShowComparision(false)}
            fullWidth={true}
            maxWidth="lg"
            PaperProps={{
              style: {
                borderRadius: '20px',
                boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#D4D4D4',
                padding: '20px'

              },
            }}
          >
            <DialogTitle style={{ position: 'relative', paddingTop: '5px' }}>
              <span>
                Compare Designs
              </span>
              <IconButton
                aria-label="close"
                onClick={() => handleShowComparision(false)}
                style={{ position: 'absolute', right: '5px', top: '5px' }}
              >
                <MdClose style={{ fontSize: '20px' }} />
              </IconButton>
              <FaDownload
                style={{ position: 'absolute', right: '60px', top: '10px', cursor: 'pointer' }}
                onClick={generateComparePDF}
                title='Download Compare Design'>
                Download
              </FaDownload>
            </DialogTitle>
            <DialogContent>
              <div className="compare-design-image-grid">
                {compareImages && compareImages.map((item, index) => (
                  <div key={index} className="comparison-container">
                    <h3 style={{ marginLeft: '15px' }}>{item.design_name}</h3>
                    <div className="comparison-images" style={{
                      borderRadius: '10px',
                      padding: '10px',
                    }}>
                      {item.design_images.map((image, imageIndex) => (
                        <img
                          key={imageIndex}
                          src={`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${image.filePath}`}
                          className="design-image-image"
                          alt={image.fileName}
                          style={{
                            maxWidth: '100%',
                            height: 'auto',
                            borderRadius: '5px',
                            marginBottom: '10px',
                          }}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </DialogContent>
          </Dialog>

          {showSelected && (
            <div className="drawer open">
              <div className="drawer-header">
                <div className="header-row">
                  <h2 className="sticky-header" style={{ marginBottom: 0, padding: "5px" }}>Selected Images</h2>
                  <i className="fas fa-times close-drawer-icon-select-item" onClick={() => setShowSelected(false)} title="Close Drawer"
                  ></i>
                </div>
                {selectedImages && (
                  <div className="action-row" style={{ marginBottom: 0 }}>
                    <label className="select-all-label">
                      <div className="select-all-checkbox">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </div>
                      <div className="select-all-label-text">Select All</div>
                    </label>
                    {selectAll && (
                      <div className="delete-selected-button" onClick={() => handleRemoveConfirmation()}>
                        <i className="fas fa-trash fa-xs" title='All Design Remove From Cart'></i>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div ref={drawerContentRef} className="drawer-content">
                <ul className='drawer-col'>
                  {selectedImages && selectedImages.map((image, index) => (
                    <li key={index} className="selected-image-item">
                      <div className="select-image-container">
                        <i
                          className={`fas fa-star${image.isStar ? ' starred' : ''}`}
                          style={{ color: image.isStar ? '#ff5733' : 'gray', cursor: 'pointer' }}
                          onClick={() => handleStarClick(image)}
                          title={image.isStar ? 'Remove Star' : 'Add Star'}
                        ></i>
                        <div className='drawer-image' onClick={() => handleImageClick(image)}>
                          <img
                            src={`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${image.design_file}`}
                            alt={image.design_name}
                            loading="lazy"
                            className="selected-image"
                            onError={(e) => {
                              e.target.src = "../../../../images/no-image-icon.png";
                            }}
                          />
                        </div>
                        <div className='masonry-text-effect'>
                          {image.design_name && (
                            <p className="image-size" style={{ fontSize: "13px" }}> {image.design_name === image.buyer_design_name
                              ? image.design_name
                              : image.buyer_design_name
                                ? ` ${image.buyer_design_name} | ${image.design_name}`
                                : ` ${image.design_name}`}</p>
                          )}
                          <div className='masonry-text-data'>
                            {image.height_width && (
                              <p className="image-size">
                                {convertToMMFormat(image.height_width)}
                                {/* {image.height_width.split('X')[0].trim()} x {image.height_width.split('X')[1].trim()} */}
                              </p>
                            )}
                            {/* {image.design_category && (
                              <p className="design-category">{image.design_category}</p>
                            )}
                            {image.color && (
                              <p className="design-category">{image.color}</p>
                            )} */}
                          </div>
                          {image.finish_array && image.finish_array.length > 0 && (
                            <p className="finish-name" style={{ fontStyle: 'italic' }}>
                              {image.finish_id.map((finish, finishIndex) => finish.finish_name).join(' | ')}
                            </p>
                          )}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 'auto', gap: 5 }}>
                          <div style={{ display: 'flex', gap: 15 }}>
                            <div className="delete-selected-button" onClick={() => handleEditClick(image)}>
                              <i className="fas fa-edit fa-xs" title='Edit Details'></i>
                            </div>
                            <div className="delete-selected-button" onClick={() => handleSingleRemoveConfirmation(image)}>
                              <i className="fas fa-trash fa-xs" title='Remove From Cart'></i>
                            </div>
                          </div>
                          {image.quantity && (
                            <div>
                              <p className="design-category"><strong>{image.unit?.toUpperCase()}: {image.quantity}</strong></p>
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                {Object.keys(totalQuantityByUnit).length > 0 && (
                  <div style={{ margin: '5px', padding: '15px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}>
                    <h3 style={{ marginBottom: '15px', color: '#333' }}>Total Quantities</h3>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                      {Object.keys(totalQuantityByUnit).map((unit) => (
                        <div key={unit} style={{ textAlign: 'center', padding: '15px', display: 'flex' }}>
                          <div>
                            <strong>{` ${unit.toUpperCase()}: `}</strong>
                          </div>
                          <div style={{ marginLeft: "5px" }}>
                            <p>{totalQuantityByUnit[unit] || 0}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {imageLoading && (
                  <div className="loader-container">
                    <div className="main_loader"></div>
                  </div>
                )}
                {isScrolled && (
                  <div className="scroll-to-top-select-item" onClick={handleMoveTop} title='Go To Top'>
                    <i className="fas fa-arrow-up"></i>
                  </div>
                )}
                {attachmentData && attachmentData.length > 0 &&
                  <button onClick={handleViewAttachments} style={{ backgroundColor: "#112d4a" }}>
                    View Attachments
                  </button>
                }
                {isAdmin && selectedImages && selectedImages.length > 0 && (
                  <div className="repeater-container">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div>
                        <h3>Additionals</h3>
                      </div>
                      <div>
                        <IoIosAddCircleOutline onClick={handleAddRepeaterItem} className='icon' title='Add' />
                      </div>
                    </div>
                    {repeaterItems.map((item) => (
                      <div key={item.id} className="repeater-item" >
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', marginTop: '10px', gap: '5px' }}>
                          <input
                            type="file"
                            id={`fileInput_${item.id}`}
                            onChange={(e) => handleFileChange(item.id, e)}
                            style={{ marginRight: '10px' }}
                          />
                          {/* Custom label for file input */}
                          <label htmlFor={`fileInput_${item.id}`} className="file-input-label">
                            <i className="fas fa-upload fa-xs" title='Upload'></i> Choose File
                          </label>
                          <TextareaAutosize
                            value={item.text}
                            onChange={(e) => handleTextChange(item.id, e)}
                            placeholder="Enter comment"
                            className="textarea-styled"
                          />
                          <div className="delete-selected-button" onClick={() => handleRemoveRepeaterItem(item.id)}>
                            <i className="fas fa-trash fa-xs" title='Remove'></i>
                          </div>
                        </div>
                        <div>
                          {item.file && (
                            <div className="file-name">
                              <span className="file-icon">&#128193;</span> {/* File icon, you can replace this with your own icon */}
                              <span className="file-text">{item.file.name}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                    <button onClick={handleRepeaterSubmit} style={{ backgroundColor: "#112d4a" }}>
                      Confirm Attachment
                    </button>
                  </div>
                )}
                <div className="button-container">
                  {selectedImages && selectedImages.length > 0 && (
                    <button onClick={generatePdfCatalogue}>Download Catalogue</button>
                  )}
                </div>

                <Dialog open={openConfirmation} onClose={handleCancelRemove}>
                  <DialogTitle>Confirmation</DialogTitle>
                  <DialogContent>
                    Are you sure you want to remove the selected items?
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCancelRemove} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleRemoveItem} color="primary">
                      Remove
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={openSingleConfirmation} onClose={handleSingleCancelRemove}>
                  <DialogTitle>Confirmation</DialogTitle>
                  <DialogContent>
                    Are you sure you want to delete these items?
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleSingleCancelRemove} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={() => handleSingleRemoveItem(setDeleteItem)} color="primary">
                      Remove
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={viewAttachmentsOpen} onClose={() => setViewAttachmentsOpen(false)}>
                  <DialogTitle>
                    View Attachments
                    <IconButton aria-label="close" onClick={() => setViewAttachmentsOpen(false)} style={{ position: 'absolute', right: '10px', top: '10px' }}>
                      <MdClose style={{ fontSize: '20px' }} />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <div className="attachment-grid">
                      {attachmentData && attachmentData.map((attachment, index) => (
                        <div key={index} className="attachment-item">
                          {editingIndex === index ? (
                            <div>
                              <TextField
                                fullWidth
                                value={editedMessage}
                                onChange={(e) => setEditedMessage(e.target.value)}
                                placeholder="Edit message..."
                                className="editable-input"
                              />
                              <div>
                                <div onClick={() => handleSaveClick(index, attachment._id)}>
                                  <MdOutlineSaveAlt style={{ fontSize: '20px', cursor: 'pointer' }} title='Save' />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="message-container">
                              <div className="message-row">
                                <p className='message-style'>{attachment.message}</p>
                                <div onClick={() => handleEditMessage(index)} disabled={editingIndex !== null}>
                                  <FaEdit style={{ fontSize: '15px', cursor: 'pointer' }} title='Edit Message' />
                                </div>
                                <div onClick={() => handleDeleteConfirmation(attachment._id)}>
                                  <MdDelete style={{ fontSize: '15px', cursor: 'pointer' }} title='Delete Image' />
                                </div>
                                <div>
                                  <i
                                    className={`fas fa-star${attachment.isStar ? ' starred' : ''}`}
                                    style={{ color: attachment.isStar ? '#ff5733' : 'gray', cursor: 'pointer' }}
                                    onClick={() => handleAttachmentStarClick(attachment)}
                                    title={attachment.isStar ? 'Remove Star' : 'Add Star'}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          )}
                          <div>
                          </div>
                          {/* Add other details as needed */}
                          {attachment.image_name && (
                            <img
                              src={`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_UPLOAD_BASE_URL}/attachment/${attachment.image_name}`}
                              alt={attachment.image_name}
                              className="attachment-image"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </DialogContent>
                </Dialog>

                <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
                  <DialogTitle>Confirmation</DialogTitle>
                  <DialogContent>
                    Are you sure you want to delete this attachment?
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setDeleteConfirmationOpen(false)} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleDeleteImage} color="primary">
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          )}

          {showFormPopup && (
            <div className="popup-container">
              <div className="popup-content">
                <h2>Register</h2>
                {/* Add loading, success, and error messages */}
                {formSubmissionStatus === 'loading' && <p className="status-message">Submitting...</p>}
                {formSubmissionStatus === 'success' && <p className="status-message success">Data added successfully!</p>}
                {formSubmissionStatus === 'error' && <p className="status-message error">Error submitting form. Please try again.</p>}
                <form onSubmit={handleCatalogSubmit}>
                  {/* Add input elements for company name, customer name, email, and country */}
                  <TextField
                    label="Company Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    autoComplete='off'
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                  />
                  <TextField
                    label="Customer Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    autoComplete='off'
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    required
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    autoComplete='off'
                    value={email}
                    onChange={handleEmailChange}
                    error={!isEmailValid}
                    helperText={!isEmailValid ? 'Invalid email format' : ''}
                    required
                  />
                  <TextField
                    label="Country"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    autoComplete='off'
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  />

                  {/* Add a submit button */}
                  <Button type="submit" variant="contained" color="primary" style={{ marginTop: '10px', textTransform: 'none' }}>
                    Generate Catalogue
                  </Button>
                  {isAdmin && (
                    <>
                      <Button onClick={generateLink} variant="contained" color="primary" style={{ marginTop: '10px', textTransform: 'none' }}>
                        Generate Link
                      </Button>
                      {generateUrl && generateOtp && (
                        <div className="generated-info">
                          <p>
                            <span className="info-label">Link:</span>
                            <span className="copy-icon" onClick={() => copyToClipboard(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_FRONT_BASE_URL}${generateUrl}`)}>📋</span>
                          </p>
                          <p>
                            <span className="info-label">OTP:</span>
                            <span className="copy-icon" onClick={() => copyToClipboard(generateOtp)}>📋</span>
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </form>
                <i className="fas fa-times form-close-drawer-icon" onClick={() => {
                  setShowFormPopup(false);
                  setGenerateOtp("")
                  setGenerateUrl("")
                  // Reset form submission status when closing the popup
                  setFormSubmissionStatus('idle');
                }}></i>
              </div>
            </div>
          )}

          {isModalOpen && (
            <>
              <div className="modal gallery-modal">
                <div className="modal-content">
                  <span className="close-icon" onClick={() => setIsModalOpen(false)}>
                    &#x2716;
                  </span>
                  <div className="modal-image-container">
                    <div className="index-counter">
                      {`${currentIndex + 1}`}
                    </div>
                    {
                      sliderImages && sliderImages.length > 1 &&
                      <div className="btnPrev" role="img" aria-label="Previous" onClick={prevButton}>
                        <FaChevronLeft />
                      </div>
                    }
                    <div className='gallery-pop-image'>
                      <img
                        src={`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE_IMAGE_BASE_URL}product-design/${currentSelectedImage.design_file}`}
                        alt=""
                        className="modal-img"
                        loading="lazy"
                      />
                    </div>
                    {
                      sliderImages && sliderImages.length > 1 &&
                      <div className="btnNext" role="img" aria-label="Next" onClick={nextButton}>
                        <FaChevronRight />
                      </div>
                    }
                  </div>
                  <div className="flex-container">
                    <div>
                      <i className="fas fa-layer-group"></i>
                      <span> {currentSelectedImage.no_of_randome} </span>
                    </div>
                    <div>{currentSelectedImage.design_name}</div>
                  </div>
                  <div className="modal-nav" style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
                    <div>
                      {currentSelectedImage.img_url_for_3d_view && (
                        <a href={currentSelectedImage.img_url_for_3d_view} target="_blank" rel="noopener noreferrer">
                          <Tb360View style={{ fontSize: "30px", color: 'white' }} title='360 Link' />
                          {/* <img src='../../../../logo/360-degrees.png' height={45} alt="" title='360 Link'></img> */}
                        </a>
                      )}
                    </div>
                    <div className="design_download" onClick={() => handleDownload(currentSelectedImage.design_name)}>
                      <i className="fas fa-download" title='Download Images' style={{ color: 'white' }}></i>
                    </div>
                    <div className="design_download" onClick={() => handleDownloadCatalogue(currentSelectedImage)}>
                      <i className="fas fa-file-download" title='Download Catalogue' style={{ color: 'white' }}></i>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {showScrollButton && (
            <div className="scroll-to-top" onClick={scrollToTop} title='Go To Top'>
              <i className="fas fa-arrow-up"></i>
            </div>
          )}
          {compareImages && compareImages.length >= 2 && (
            <div className="compare-button-container">
              <>
                <Button onClick={handleResetCompareImage} className='button' title='Reset Randomes' style={{ paddingBottom: '0px' }}>
                  <span className='compare-reset'>&#8635;</span>
                </Button>
                <Button onClick={() => handleShowComparision(true)} className='button' title='Compare Randomes' style={{ paddingTop: '0px' }}>
                  <span className='compare'>&#8958;|&#8951;</span>
                </Button>
              </>
            </div>
          )}
          {!showScrollButton && scrollPosition > 0 && (
            <div className="scroll-to-top-button" onClick={scrollToLastPosition} title='Go To Last Image'>
              <i className="fas fa-arrow-down"></i>
            </div>
          )}

          {isEditDialogOpen && (
            <div className="popup-container">
              <div className="popup-content">
                <h2>Add Details</h2>
                {designName && (
                  <div style={{ marginBottom: '8px' }}>
                    <p>{designName}</p>
                  </div>
                )
                }
                <form onSubmit={handleDesignSubmit}>
                  {/* Add input elements for company name, customer name, email, and country */}
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="demo-simple-select-placeholder"
                      shrink={!!selectedOption}
                      style={{ display: 'block', color: '#757575' }}
                    >
                      Select Unit
                    </InputLabel>
                    <Select
                      label="Select Unit"
                      value={selectedOption}
                      onChange={selectedType}
                      fullWidth
                      displayEmpty
                      inputProps={{
                        name: 'unit',
                        id: 'demo-simple-select-placeholder',
                      }}
                      margin="normal"
                    >
                      <MenuItem value="" disabled>
                        Select Unit
                      </MenuItem>
                      <MenuItem value="m2">M2</MenuItem>
                      <MenuItem value="ctr">Ctr</MenuItem>
                      <MenuItem value="boxes">Boxes</MenuItem>
                      <MenuItem value="sqft">Sqft</MenuItem>
                      <MenuItem value="pallets">Pallets</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Quantity"
                    type="number"
                    value={quantity}
                    onWheel={(e) => e.target.blur()}
                    // onChange={(e) => setQuantity(Math.max(0, parseInt(e.target.value, 10)))}
                    onChange={(e) => {
                      // Allow only numeric and decimal input
                      const inputValue = e.target.value.replace(/[^0-9.]/g, '');
                      setQuantity(inputValue);
                    }}
                    fullWidth
                    margin="normal"
                  // inputProps={{ min: 1 }}
                  />
                  <TextField
                    label="Price"
                    type="text"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Comment"
                    multiline
                    rows={4}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <div className="file-input-container">
                    <input
                      type="file"
                      id="fileInput"
                      onChange={(e) => setUploadImage(e.target.files[0])}
                      style={{ marginRight: '10px' }}
                    />
                    <label htmlFor="fileInput" className="file-input-label">
                      <i className="fas fa-upload fa-xs" title='Upload'></i> Choose File
                    </label>
                    {/* Render the file name if a file is selected */}
                    {uploadImage && (
                      <p className="file-name">{uploadImage.name}</p>
                    )}
                  </div>
                  {/* Add a submit button */}
                  <Button type="submit" variant="contained" color="primary" style={{ marginTop: '10px', textTransform: 'none' }}>
                    Save
                  </Button>
                </form>
                <i className="fas fa-times form-close-drawer-icon" onClick={() => {
                  setEditDialogOpen(false);
                }}></i>
              </div>
            </div>
          )}
        </>
      )
      }

    </>
  );
};
export default Image;
