// ErrorPage.js

import React from 'react';
import './error.css';

const ErrorPage = () => {
	return (
		<div className="error-container">
			<div className="error-content">
				<h1>404</h1>
				<p>Page not found</p>
			</div>
		</div>
	);
};

export default ErrorPage;
