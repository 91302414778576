import React from 'react';
import './home.css'; // Import the corresponding CSS file for styling

const Home = () => {
	return (
		<div className="home-container">
			<div className="center-content">
				<img src={`../../../../logo/Italica_logo2-removebg-preview.png`} alt="Company Logo" className="company-logo" />
				<div className='image-container'>
					<div className="filter-container" style={{ margin: 0 }}>
						<div className="welcome-container">
							<div className="welcome-content">
								<h1>Welcome to Italica Image Gallery</h1>
								<i>Explore and enjoy a wide collection of beautiful images.</i>
								<h2 className="mb-4">Discover the possibilities with our innovative designs and see what we can do for you.</h2>
								<a variant="primary">Get in touch with the  <b>Italica Administrator</b>  to get started</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
