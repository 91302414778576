import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import ErrorPage from './pages/Error/error';
import Home from './pages/Home/home';
import Image from './pages/MasonryImage/image';
// import Home from './pages/home';
import { Route, Routes } from 'react-router-dom';

function App() {

  return (
    <div className="App">
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        draggable
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/design/:slug" element={<Image />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
