import { enc, SHA256 } from 'crypto-js';
export const colors = [
	{ _id: '1', label: 'Aqua' },
	{ _id: '2', label: 'Black' },
	{ _id: '3', label: 'Beige' },
	{ _id: '4', label: 'Blue' },
	{ _id: '5', label: 'Brown' },
	{ _id: '6', label: 'Bianco' },
	{ _id: '7', label: 'Gray' },
	{ _id: '8', label: 'White' },
];

export const designType = [
	{ _id: '1', label: 'New' },
	{ _id: '2', label: 'Running' },
];

export function convertToMMFormat(dimensions) {
	const [width, height] = dimensions.split(' X ');
	return `${width} mm X ${height} mm`;
}

export function generateSlugByManualData(obj) {
	return `${obj.country.toLowerCase().replace(/\s+/g, '-')}-${obj.customerName.toLowerCase().replace(/\s+/g, '-')}-${obj.selectedImages.length}`;
}

export function encryptSlug(slug) {
	return SHA256(slug).toString(enc.Hex);
}

export function generateRandomOTP(length) {
	const digits = '0123456789';
	let otp = '';
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * digits.length);
		otp += digits[randomIndex];
	}
	return otp;
}
